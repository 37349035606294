import React, { Component } from "react";
import { Button, Spin, Upload, Select, DatePicker, Radio, Input } from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import SlateEditor from "../../../components/CommentTagEditor";
import axiosJSON from "../../../util/Api";
import moment from "moment-timezone";
import { serialize } from "../../../components/Editor/parseJson";
import getDeserializeText from "../../../util/DeserializingText";
import { messageError, messageSuccess } from "../../../util/AlertMessage";
import { putData } from "../../../util/trackingAPI";

const { TextArea } = Input;
const beforeMatch = /^@(\w+)$/;
const beforeStr = "@";
const afterStr = "";

class AddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateFiles: [],
      update: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      loading: false,
      userList: [],
      selectedUsers: [],
      sprintStartDate: null,
      sprintEndDate: null,
      blocker: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      updateType: "project",
      content: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      flag: false,
      updateFlag: true,
      blockerAdded: false,
      blockerResolved: null,
      blockerSolution: "",
      isBlocker: null,
    };
  }

  componentDidMount() {
    this.getUsersList("");
    if (this.props.editUpdate && this.props.projectUpdate) {
      // var text = getDeserializeText(this.props.projectUpdate.update)

      // var text = null, data = this.props.projectUpdate.update;
      // if (this.props.projectUpdate.update === "<p></p>") {
      //   text = [{
      //     type: 'paragraph',
      //     children: [
      //       {text: ''},
      //     ],
      //   }]

      // }
      // else {
      //   text = deserialize(new DOMParser().parseFromString(data, 'text/html').body)
      // }
      this.setState({
        update: getDeserializeText(this.props.projectUpdate.update),
        content: getDeserializeText(this.props.projectUpdate.update),
        flag: true,
        selectedUsers: this.props.projectUpdate.tagged_user,
        sprintStartDate:
          this.props.projectUpdate.start &&
          this.props.projectUpdate.start !== ""
            ? moment(this.props.projectUpdate.start)
            : null,
        sprintEndDate:
          this.props.projectUpdate.end && this.props.projectUpdate.end !== ""
            ? moment(this.props.projectUpdate.end)
            : null,
        updateType: this.props.projectUpdate.type,
        blocker: getDeserializeText(this.props.projectUpdate.blocker),
      });
    } else {
      this.setState({
        flag: true,
      });
    }
  }

  handleFileChange = (info) => {
    putData(`file changed`, "engineering_module");
    let fileList = [...this.state.updateFiles];
    fileList.push(info.file);
    this.setState({
      updateFiles: fileList,
    });
  };

  customRequest = (file) => {
    console.log(file);
  };

  onCancel = (file) => {
    putData(`cancel clicked`, "engineering_module");
    let fileList = [...this.state.updateFiles];
    let index = fileList.indexOf(file);
    if (index > -1) {
      fileList.splice(index, 1);
    }
    this.setState({ updateFiles: fileList });
  };

  getUsersList = (param) => {
    axiosJSON
      .get("api/employee/?query=" + param)
      .then(({ status, data }) => {
        this.setState({
          userList: data.data,
        });
      })
      .catch((error) => {
        messageError(error.message);
      });
  };

  onSelectUser = (value) => {
    putData(`user selected = { ${value} }`, "engineering_module");
    let user = [...this.state.selectedUsers];
    user.push(value);
    this.setState({ selectedUsers: user });
  };

  handleUpdateChange = (e) => {
    this.setState({ update: e, content: e });
  };

  handleBlockerChange = (e) => {
    putData(`blocker value changed`, "engineering_module");
    this.setState({
      blocker: e,
      blockerAdded: serialize(e) !== "<p></p>" ? true : false,
    });
  };

  handleUpdateTypeChange = (value) => {
    putData(`update type changed = { ${value} }`, "engineering_module");
    this.setState({
      updateType: value,
      updateFlag: false,
    });
    if (value === "Project".toLowerCase()) {
      this.setState({ updateFlag: true });
    }
  };

  onStartDateChange = (date, dateString) => {
    putData(`start date = { ${dateString} }`, "engineering_module");
    this.setState({
      sprintStartDate: dateString,
    });
  };

  onEndDateChange = (date, dateString) => {
    putData(`end date = { ${dateString} }`, "engineering_module");
    this.setState({
      sprintEndDate: dateString,
    });
  };

  handleSubmit = () => {
    putData(`submit clicked`, "engineering_module");
    let tag = [];
    this.state.selectedUsers.map((user) => {
      tag.push(user.id);
      return null;
    });
    if (serialize(this.state.update) === "") {
      return messageError("Project Update is required !!!");
    }

    if (this.state.updateFlag && !this.state.sprintStartDate) {
      return messageError("Sprint start date is required !!!");
    }

    if (this.state.isBlocker === null) {
      return messageError("Please indicate whether a blocker is present or not !!!");
    }

    if(this.state.isBlocker && serialize(this.state.blocker) === "") {
      return messageError("Blocker is required !!!");
    }

    if (this.state.isBlocker && this.state.blockerAdded && this.state.blockerResolved === null) {
      return messageError("Blocker status is required !!!");
    }

    this.setState({
      loading: true,
    });
    const formData = new FormData();
    formData.append("update", serialize(this.state.update));
    if(this.state.isBlocker){
      formData.append("blocker", serialize(this.state.blocker));
    }
    if (this.state.isBlocker && this.state.blockerAdded) {
      formData.append("blocker_resolved", this.state.blockerResolved);
      formData.append("blocker_solution", this.state.blockerSolution);
    }
    if (this.state.sprintStartDate) {
      formData.append(
        "start",
        this.state.sprintStartDate && this.state.sprintStartDate !== ""
          ? moment(this.state.sprintStartDate).format("YYYY-MM-DD")
          : null
      );
    }

    if (this.state.sprintEndDate) {
      formData.append(
        "end",
        this.state.sprintEndDate && this.state.sprintEndDate !== ""
          ? moment(this.state.sprintEndDate).format("YYYY-MM-DD")
          : null
      );
    }

    formData.append("type", this.state.updateType);
    formData.append("tagged_user", tag);
    this.state.updateFiles.forEach((file) => {
      formData.append("files", file.originFileObj);
    });
    axiosJSON
      .post(`/api/project/${this.props.project_id}/updates/`, formData)
      .then((res) => {
        this.setState({
          loading: false,
          update: [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
          blocker: [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
        });
        this.props.closeModal();
        messageSuccess(res.data.message);
      })
      .catch((error) => {
        this.setState({ loading: false });
        messageError(error);
        this.props.closeModal();
      });
  };

  disableEndDate = (current) => {
    if (this.state.sprintStartDate) {
      let d = new Date(this.state.sprintStartDate);
      d.setDate(d.getDate() - 1);
      return current && current <= moment(d).endOf("day");
    }
  };

  disableStartDate = (current) => {
    if (this.state.sprintEndDate) {
      let d = new Date(this.state.sprintEndDate);
      d.setDate(d.getDate());
      return current && current >= moment(d).endOf("day");
    }
  };

  render() {
    return (
      <div style={{ height: "" }} className="pr-2">
        <Spin spinning={this.state.loading}>
          <div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="pt-2 pl-1 font-weight-500">Update Type</label>
              </div>
              <div className="col-md-8">
                <Select
                  showSearch
                  notFoundContent={null}
                  style={{ width: "100%" }}
                  placeholder="Select Update Type"
                  optionFilterProp="children"
                  onChange={this.handleUpdateTypeChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={this.state.updateType}
                >
                  <Select.Option value={"project"}>Project</Select.Option>
                  <Select.Option value={"training"}>Training</Select.Option>
                </Select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="pt-2 font-weight-500">
                  {this.state.updateFlag ? (
                    <span style={{ color: "red", fontSize: 9 }}>*</span>
                  ) : null}
                  Sprint Start Date
                </label>
              </div>
              <div className="col-md-8">
                <DatePicker
                  format="MM-DD-YYYY"
                  placeholder="Sprint Start Date"
                  style={{ width: "100%" }}
                  onChange={(date, dateString) =>
                    this.onStartDateChange(date, dateString)
                  }
                  value={
                    this.state.sprintStartDate !== null &&
                    this.state.sprintStartDate !== ""
                      ? moment(this.state.sprintStartDate)
                      : ""
                  }
                  disabledDate={this.disableStartDate}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="pt-2 pl-1 font-weight-500">
                  Sprint End Date
                </label>
              </div>
              <div className="col-md-8">
                <DatePicker
                  format="MM-DD-YYYY"
                  placeholder="Sprint End Date"
                  style={{ width: "100%" }}
                  onChange={(date, dateString) =>
                    this.onEndDateChange(date, dateString)
                  }
                  value={
                    this.state.sprintEndDate !== null &&
                    this.state.sprintEndDate !== ""
                      ? moment(this.state.sprintEndDate)
                      : ""
                  }
                  disabledDate={this.disableEndDate}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="pt-2 font-weight-500">
                  {this.state.updateFlag ? (
                    <span style={{ color: "red", fontSize: 9 }}>*</span>
                  ) : null}
                  Is there any blocker?
                </label>
              </div>
              <div className="col-md-8 pt-2">
                <Radio.Group
                  value={this.state.isBlocker}
                  onChange={(e) => {
                    this.setState({
                      isBlocker: e.target.value,
                      blocker: [
                        {
                          type: "paragraph",
                          children: [{ text: "" }],
                        },
                      ],
                      blockerResolved: null
                    });
                  }}
                  buttonStyle="solid"
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
            </div>

            {this.state.isBlocker && (<div className="row mt-3">
              <div className="col-md-12">
                <label className="pt-2 pl-1 font-weight-500">{this.state.updateFlag ? (
                    <span style={{ color: "red", fontSize: 9 }}>*</span>
                  ) : null}Blocker</label>
              </div>
              <div className="col-md-11 mx-auto border-radius-10 border px-4 py-2">
                {this.state.flag && (
                  <SlateEditor
                    beforeMatch={beforeMatch}
                    afterStr={afterStr}
                    beforeStr={beforeStr}
                    onSelectUser={this.onSelectUser}
                    value={this.state.blocker}
                    setData={this.handleBlockerChange}
                    user_list={this.state.userList}
                  />
                )}
              </div>
            </div>)}

            {this.state.isBlocker && this.state.blockerAdded && (
              <div className="row mt-3">
                <div className="col-md-4">
                  <label className="pt-2 font-weight-500">
                    {this.state.updateFlag ? (
                      <span style={{ color: "red", fontSize: 9 }}>*</span>
                    ) : null}
                    Blocker Status
                  </label>
                </div>
                <div className="col-md-8 pt-2">
                  <Radio.Group
                    //defaultValue={this.state.blockerResolved}
                    value={this.state.blockerResolved}
                    onChange={(e) => {
                      this.setState({
                        blockerResolved: e.target.value,
                        blockerSolution: e.target.value
                          ? this.state.blockerSolution
                          : "",
                      });
                    }}
                    buttonStyle="solid"
                  >
                    <Radio value={true}>Resolved</Radio>
                    <Radio value={false}>UnResolved</Radio>
                  </Radio.Group>
                </div>
              </div>
            )}

            {this.state.blockerAdded && this.state.blockerResolved && (
              <div className="row mt-3">
                <div className="col-md-4">
                  <label className="pt-2 font-weight-500">
                    Blocker Solution
                  </label>
                </div>
                <div className="col-md-8">
                  <TextArea
                    placeholder="Enter the Bloker solution"
                    value={this.state.blockerSolution}
                    onChange={(e) => {
                      this.setState({ blockerSolution: e.target.value });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col-md-12">
                <label className="pt-2 font-weight-500">
                  <span style={{ color: "red", fontSize: 13 }}>*</span>
                  Project Update
                </label>
              </div>
              <div className="col-md-11 mx-auto border-radius-10 border px-4 py-2">
                {this.state.flag && (
                  <SlateEditor
                    beforeMatch={beforeMatch}
                    afterStr={afterStr}
                    beforeStr={beforeStr}
                    onSelectUser={this.onSelectUser}
                    value={this.state.update}
                    setData={this.handleUpdateChange}
                    user_list={this.state.userList}
                  />
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-3">
                <label className="pt-2 pl-1 font-weight-500">
                  Upload Documents
                </label>
              </div>
              <div className="col-md-9">
                <Upload
                  accept="application/pdf,.docx,image/jpeg,image/png"
                  onChange={(f) => this.handleFileChange(f)}
                  multiple={true}
                  customRequest={(f) => this.customRequest(f)}
                  showUploadList={false}
                >
                  <Button>
                    <UploadOutlined />
                    &nbsp; Click to Upload
                  </Button>
                </Upload>
              </div>
            </div>

            {this.state.updateFiles.length > 0 ? (
              <div>
                <label style={{ fontWeight: "bold" }}>Selected Files</label>
                {this.state.updateFiles.map((file) => (
                  <div className="text-file-attached">
                    <span className="mb-2">
                      {file.name}
                      <CloseCircleOutlined
                        className="deleteicon"
                        style={{
                          color: "red",
                          fontSize: 14,
                          marginLeft: "10px",
                          borderWidth: "0px",
                          borderColor: "white",
                          float: "revert",
                          marginTop: "0px",
                        }}
                        onClick={() => this.onCancel(file)}
                      />
                    </span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div
            className="sub-button my-3 text-center"
            style={{ clear: "both", float: "right" }}
          >
            <Button
              className="ant-btn-outline float-none"
              onClick={this.props.onCancelHandle}
            >
              Cancel
            </Button>
            <Button className="float-none" onClick={this.handleSubmit}>
              Submit
            </Button>
          </div>
          <br clear="all" />
        </Spin>
      </div>
    );
  }
}

export default AddUpdate;
