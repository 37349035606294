import React from "react";
import imgnotfound from "../assets/images/notfound.png";
import { FiArrowLeft } from "react-icons/fi";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const NotFound = ({ history }) => {
  return (
    <div className="page-notfound-wrapper">
      <div className="page-notfound-container d-flex flex-wrap justify-content-center align-items-center">
        <div className="page-notfound-left">
          <p className="page-notfound-sub-title">404 Error</p>
          <h2 className="page-notfound-title">Page not found</h2>
          <p className="page-notfound-bodytext">
            Sorry , the page you’re looking for doesn’t exist or has been moved.
            here are something helpful links
          </p>
          <div className="page-notfound-btns-wrapper d-flex flex-wrap">
            <p class="go-back-btn" onClick={()=>history.goBack()}>
              <FiArrowLeft className={"list-page-icons"} />
              Go back
            </p>
            <p class="go-to-dashboard-btn" onClick={()=>history.push({pathname:"/dashboard"})}>
              Go to dashboard
            </p>
          </div>
        </div>
        <div className="page-notfound-right">
          <img src={imgnotfound} alt="Not Found" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
