import { message } from "antd";

export function messageError(data, key, duration) {
  return message.error({
    content: data,
    classname: "custom-error",
    key: key ? key : "1",
    // duration: duration ? duration : 1,
  });
}
export function messageSuccess(data, key, duration) {
  return message.success({
    content: data,
    classname: "custom-sucess",
    key: key ? key : "1",
    // duration: duration ? duration : 1,
  });
}
export function messageInfo(data, key, duration) {
  return message.info({
    content: data,
    classname: "custom-info",
    key: key ? key : "1",
    // duration: duration ? duration : 1,
  });
}
export function messageWarning(data, key, duration) {
  return message.warning({
    content: data,
    classname: "custom-warning",
    key: key ? key : "1",
    // duration: duration ? duration : 1,
  });
}

