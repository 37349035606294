import React, {useEffect, useState} from "react";
import { Select} from "antd";
import axiosJSON from "../util/Api";
import {CustomModal as Modal} from "../util/CustomModal"
import { messageError } from "../util/AlertMessage";

const HandoverUser = (props) => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setUser] = useState("");
  const [selectedHandoverUser, setHandoverUser] = useState("");

  useEffect(() => {
    getUserList("")
  }, [])

  const getUserList = (query) => {
    axiosJSON.get('/api/impersonate/?query=' + query)
      .then(({data}) => {
        setUserList(data.data)
      })
      .catch(error =>
        messageError(error)
      )
  }
  const handleChange = (value) => {
    setUser(value)
  }
  const onHandoverChange = (value) => {
    setHandoverUser(value)
  }
  const onSubmit = () => {
    const body = {
      'user_id': parseInt(selectedUser),
      'handover_id': parseInt(selectedHandoverUser),
    }
    axiosJSON.post('/api/impersonate/handover/', body)
      .then(({data}) => {
        props.openImpersonate(false);
      })
      .catch(error =>
        messageError(error)
      )
  }

  return (
    <Modal
      title="Handover User"
      onCancel={() => props.openHandover(false)}
      visible={props.handoverButton}
      onOk={onSubmit}
    >

      <div className="row">
        <div className="col-6">
          <div><label>Select User</label></div>
          <br/>
          <div style={{marginTop: '10px'}}><label>Handover User</label></div>
        </div>
        <div className="col-6">
          <div><Select
            showSearch
            notFoundContent={null}
            value={selectedUser}
            onChange={handleChange}
            onSearch={getUserList}
            style={{width: '200px'}}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              userList.length > 0 && userList.map((user, i) =>
                <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)
            }
          </Select>
          </div>
          <div style={{marginTop: '10px'}}>
            <Select
              showSearch
              notFoundContent={null}
              value={selectedHandoverUser}
              onChange={onHandoverChange}
              onSearch={getUserList}
              style={{width: '200px'}}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                userList.length > 0 && userList.map((user, i) =>
                  <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)
              }
            </Select>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HandoverUser;
