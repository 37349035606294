import React, { useState, useEffect } from "react";
import { Button, Input, Select, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  BsInfoCircleFill,
  BsPlusCircleFill,
  BsXCircleFill,
} from "react-icons/bs";
import { messageError } from "../../../../util/AlertMessage";

const GenericFormComponent = (props) => {
  const [inputVal, setInputVal] = useState(null);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [name, setName] = useState("");
  const returnArrayString = (str) => {
    let arr;
    if (str[0] === "[") {
      arr = str.substring(1, str.length - 1).split(",");
    } else {
      return str.split(", ");
    }
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === '"') {
        newArr.push(arr[i].substring(1, arr[i].length - 1));
      } else {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  };

  const getValue = () => {
    if (props.fieldObj.values) {
      return props.fieldObj.values;
    } else {
      if (props.answer && props.answer.length > 0) {
        let qus = props.answer.filter((ele) => {
          return ele.question_answer.ques_id === props.fieldObj.id;
        });
        if (qus && qus[0] && qus[0].question_answer.answer === "[]") {
          return;
        }
        if (qus && qus[0] && qus[0].question_answer) {
          return returnArrayString(qus[0].question_answer.answer);
        }
      }
    }
    return;
  };

  const setValues = () => {
    if (props.fieldObj.values) {
      setInputVal(props.fieldObj.values);
    } else {
      let qus = props.answer.filter((ele) => {
        return ele.question_answer.ques_id === props.fieldObj.id;
      });
      if (qus && qus[0] && qus[0].question_answer) {
        setInputVal(qus[0].question_answer.answer);
      } else {
        setInputVal(props.fieldObj.values);
      }
    }
  };

  useEffect(() => {
    if (props.answer) {
      setValues();
    }
    // eslint-disable-next-line
  }, []);

  const onMultiSelectChange = (e) => {
    if (e && e.includes("None")) setDefaultOpen(false);
    if (e && e.length <= 0) setDefaultOpen(true);
    props.handleFieldChange(
      e.includes("None") ? ["None"] : e,
      props.fieldObj,
      null,
      null,
      props.parentId,
      props.apiQuestion,
      props.dependencId
    );
    // }
  };

  const checkDuplicate = (name, options) => {
    let option = options.find(
      (item) =>
        item.trim().replace(" ", "").toLowerCase() ===
        name.trim().replace(" ", "").toLowerCase()
    );
    return option ? true : false;
  };

  const returnShowOther = (type, options) => {
    return (
      <div className="mt-2">
        <div>
          <Input
            style={type === "multi_select" ? { width: "80%" } : null}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Tooltip
            title={`Input ${
              type === "multi_select" ? "Comma Seprated Values" : "Value"
            } and Please make Sure to Click Add Button to 
                    add them in the above List otherwise data will not be added`}
          >
            <BsInfoCircleFill className="ml-1" />
          </Tooltip>
          <Button
            type="default"
            className="ml-1 other-option-btn"
            onClick={() => {
              if (name) {
                if (!checkDuplicate(name, options)) {
                  if (type === "multi_select") {
                    onMultiSelectChange([
                      ...(getValue() ? getValue() : []),
                      ...name.split(",").map((item) => item.trim()),
                    ]);
                  } else {
                    props.handleFieldChange(
                      name,
                      props.fieldObj,
                      null,
                      null,
                      props.parentId,
                      props.apiQuestion,
                      props.dependencId
                    );
                  }
                  setName("");
                  setShowOther(false);
                } else {
                  messageError("Value already exist.");
                }
              } else {
                messageError("Please Input Value");
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
    );
  };

  const changeValues = (val) => {
    setInputVal(val);
    props.handleFieldChange(
      val,
      props.fieldObj,
      null,
      null,
      props.parentId,
      props.apiQuestion,
      props.dependencId
    );
  };

  const returnBody = (type) => {
    if (type === "integer" || type === "text") {
      return (
        <Input
          name={props.fieldObj.values}
          id={props.fieldObj.id}
          value={inputVal}
          min={type === "integer" ? 0 : null}
          type={type === "integer" ? "numeric" : null}
          onChange={(e) => {
            if (type === "integer") {
              changeValues(e.target.value.match(/\d+/g));
            } else changeValues(e.target.value);
          }}
        />
      );
    } else if (type === "long_text") {
      return (
        <TextArea
          name={props.fieldObj.values}
          id={props.fieldObj.id}
          value={inputVal}
          onChange={(e) => {
            changeValues(e.target.value);
          }}
        />
      );
    } else if (type === "option") {
      return (
        <div>
          <Select
            showSearch
            name={props.fieldObj.values}
            id={props.fieldObj.id}
            value={props.fieldObj.values}
            onChange={(value) =>
              props.handleFieldChange(
                value,
                props.fieldObj,
                null,
                null,
                props.parentId,
                props.apiQuestion,
                props.dependencId
              )
            }
          >
            {props.fieldObj &&
              props.fieldObj.options &&
              props.fieldObj.options.map((option, i) => (
                <Select.Option key={i} value={option}>
                  {option}
                </Select.Option>
              ))}
          </Select>
          {props.fieldObj && props.fieldObj.update_options && !showOther && (
            <Tooltip title="Click to Add Other Custom Options">
              <BsPlusCircleFill
                className="ml-1"
                onClick={() => setShowOther(true)}
              />
            </Tooltip>
          )}
          {props.fieldObj && props.fieldObj.update_options && showOther && (
            <Tooltip title="Click To hide Input Field">
              <BsXCircleFill
                className="ml-1"
                onClick={() => setShowOther(false)}
              />
            </Tooltip>
          )}
          {showOther && returnShowOther(type, props.fieldObj.options)}
        </div>
      );
    } else if (type === "multi_select") {
      return (
        <div>
          <Select
            showSearch
            notFoundContent={null}
            name={props.fieldObj.values}
            mode="multiple"
            placeholder={props.fieldObj.title}
            optionFilterProp="children"
            value={getValue()}
            open={defaultOpen}
            style={{ width: "96%" }}
            onFocus={() => {
              setDefaultOpen(true);
            }}
            onBlur={() => {
              setDefaultOpen(false);
            }}
            onChange={(e) => onMultiSelectChange(e)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.fieldObj &&
              props.fieldObj.options &&
              props.fieldObj.options.map((option, i) => (
                <Select.Option
                  key={i}
                  value={option}
                  disabled={getValue()?.includes("None") && option !== "None"}
                >
                  {option}
                </Select.Option>
              ))}
          </Select>
          {props.fieldObj && props.fieldObj.update_options && !showOther && (
            <Tooltip title="Click to Add Other Custom Options">
              <BsPlusCircleFill
                className="ml-1"
                onClick={() => setShowOther(true)}
              />
            </Tooltip>
          )}
          {props.fieldObj && props.fieldObj.update_options && showOther && (
            <Tooltip title="Click To hide Input Field">
              <BsXCircleFill
                className="ml-1"
                onClick={() => setShowOther(false)}
              />
            </Tooltip>
          )}
          {showOther && returnShowOther(type)}
        </div>
      );
    }
  };

  return (
    <div className="some_test my-1">
      <label className="largeFont">
        {props.fieldObj.is_required && (
          <span style={{ color: "red", fontSize: 15 }}>*</span>
        )}
        {props.fieldObj.title}
      </label>
      <div>{returnBody(props.fieldObj.answer_type)}</div>
    </div>
  );
};

export default GenericFormComponent;
