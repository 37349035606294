import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions";
import {BASE_URL} from "../../util/Api";


const UserInfo = () => {

  const dispatch = useDispatch();
  const user = useSelector(({auth}) => auth.authUser);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      {user.avatar === null ?
        <Avatar className="gx-size-40 gx-pointer gx-mr-3">{user.name.split(" ")[0].charAt(0).toUpperCase()+user.name.split(" ")[1].charAt(0).toUpperCase()}</Avatar>:
        <Avatar src={BASE_URL + user.avatar} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
      }
    </Popover>
  )

}

export default UserInfo;
