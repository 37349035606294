export const checkRoles = (roles, role, type = "all") => {
  if (roles) {
    return type === "all"
      ? role.every((data) => roles.includes(data))
      : role.some((data) => roles.includes(data));
  }
  return true;
};

export const checkExcludeRoles = (roles, role, type = "all") => {
  if (roles) {
    return type === "all"
      ? !role.every((data) => roles.includes(data))
      : !role.some((data) => roles.includes(data));
  }
  return true;
};
