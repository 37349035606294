import React, { Component } from "react";
import { Input, Button, Select, Spin } from "antd";
import axiosJSON from "../util/Api";
import { messageError, messageSuccess } from "../util/AlertMessage";
import { putData } from "../util/trackingAPI";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_id: null,
      email: "",
      c_password: "",
      phone: "",
      name: "",
      gender: "male",
      team: "",
      password: "",
      teamList: [],
      team_status: null,
      formErrors: { employee_id: "", email: "", password: "" },
      emailValid: false,
      passwordValid: false,
      employee_idValid: false,
      error: "",
      data: [],
      value: [],
      role: [],
      fetching: false,
      spinLoading: false,
    };
  }

  componentDidMount() {
    this.getTeamList();
    this.getRole();
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let employee_IDValid = this.state.employee_IDValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        var re = /\S+@\S+\.\S+/;
        if (re.test(String(value).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
        fieldValidationErrors.email = emailValid ? "" : "is not valid";
        break;
      case "employee_id":
        employee_IDValid = value.match(/[0-9]$/);
        fieldValidationErrors.employee_id = employee_IDValid
          ? ""
          : " is invalid";
        break;
      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        employee_idValid: employee_IDValid,
      },
      this.validateForm
    );
  };

  getTeamList = () => {
    axiosJSON
      .get("/api/employee/team/?query=all")
      .then((data) => {
        data.data.data.map((item, i) => this.state.teamList.push(item));
        this.setState({ team_status: data.status });
      })
      .catch(function (error) {
        messageError(error);
      });
  };

  handleChange = (e) => {
    putData(`{ ${e.target.name} } value = { ${e.target.value} }`, "log1");
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  handleInputChangeTeam = (team) => {
    putData(`team value = { ${team} }`, "log1");
    this.setState({
      team: team,
    });
  };

  getRole = () => {
    this.setState({ fetching: true });
    axiosJSON
      .get("/api/employee/role/")
      .then((data) => {
        let val = [];
        val = data.data.data.map((user) => ({
          text: `${user.name}`,
          value: user.id,
        }));
        this.setState({ data: val, fetching: false });
      })
      .catch(function (error) {
        messageError(error);
      });
  };

  addUser = () => {
    putData(`submit clicked`, "log1");
    this.setState({ spinLoading: true });
    let att = this.state.value;
    let role = this.state.role;
    att.map((item, i) => {
      return role.push(item.value);
    });

    const body = {
      employee_id: this.state.employee_id,
      name: this.state.name,
      role: role,
      phone: this.state.phone,
      email: this.state.email,
      team: this.state.team,
      gender: this.state.gender,
      password: this.state.password,
    };

    let self = this;
    if (
      this.state.employee_id &&
      this.state.name &&
      this.state.password &&
      this.state.emailValid &&
      this.state.phone &&
      this.state.role &&
      this.state.team &&
      this.state.gender
    ) {
      axiosJSON
        .post("/api/employee/", body)
        .then((data) => {
          if (data.status === 201) {
            this.setState({ spinLoading: false });
            messageSuccess("User Added Successfully.");
            this.props.handleClose();
          } else if (data.status === 400) {
            this.setState({ spinLoading: false });
            this.props.handleClose();
          } else if (data.status === 406) {
            this.setState({ spinLoading: false });
            messageError("User Already Exists");
            this.props.handleClose();
          } else if (data.status === 500) {
            this.setState({ spinLoading: false });
            this.props.handleClose();
          }
        })
        .catch(function (e) {
          self.setState({ spinLoading: false });
          self.props.handleClose();
          messageError(e);
        });
    } else {
      messageError("Email, name, password, phone, team, role cannot be empty");
      self.setState({ spinLoading: false });
    }
  };

  handleChangeSelect = (value) => {
    putData(`value selected = { ${value} }`, "log1");
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  onGenderChange = (value) => {
    putData(`gender value = { ${value} }`, "log1");
    this.setState({ gender: value });
  };

  errorClass = (error) => {
    return error.length === 0 ? "" : "has-error";
  };

  render() {
    const { fetching, data, value } = this.state;
    return (
      <div>
        <Spin spinning={this.state.spinLoading}>
          <div className="sidebaradduser">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className={`form-group`}>
                  <label> Name </label>
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={`form-group`}>
                  <label> Gender </label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Select
                    value={this.state.gender}
                    style={{ width: "100%" }}
                    placeholder="Gender"
                    optionFilterProp="children"
                    onChange={(e) => this.onGenderChange(e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option key="male" value="male">
                      Male
                    </Select.Option>
                    <Select.Option key="female" value="female">
                      Female
                    </Select.Option>
                  </Select>
                </div>
                <div
                  className={`form-group ${this.errorClass(
                    this.state.formErrors.email
                  )}`}
                >
                  <label> Email </label>
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    name="email"
                    type="text"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />

                  {this.state.formErrors.email.length > 0 ? (
                    <span style={{ color: "red" }}>
                      Email {this.state.formErrors.email}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`form-group`}>
                  <label> Role </label>
                  <span style={{ color: "red" }}>*</span>
                  <Select
                    mode="multiple"
                    labelInValue
                    value={value}
                    placeholder="Select role"
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={this.getRole}
                    onChange={this.handleChangeSelect}
                    style={{ width: "100%", height: "20px" }}
                  >
                    {data.map((d) => (
                      <Select.Option key={d.value} value={d.value}>
                        {d.text.charAt(0).toUpperCase() + d.text.slice(1)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-xs-12">
                <div
                  className={`form-group ${this.errorClass(
                    this.state.formErrors.employee_id
                  )}`}
                >
                  <label>Employee ID</label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="employee_id"
                    placeholder="Employee Id"
                    onChange={this.handleChange}
                  />
                  {this.state.formErrors.employee_id.length > 0 ? (
                    <span style={{ color: "red" }}>
                      Employee Id {this.state.formErrors.employee_id}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`form-group`}>
                  <label> Password </label>
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={`form-group`}>
                  <label> Phone </label>
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    name="phone"
                    type="text"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={`form-group`}>
                  <label> Team </label>
                  <span style={{ color: "red" }}>*</span>

                  <Select
                    showSearch
                    notFoundContent={null}
                    style={{ width: "100%" }}
                    placeholder="Team List"
                    optionFilterProp="children"
                    onChange={(e) => this.handleInputChangeTeam(e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.teamList.map((item, i) => (
                      <Select.Option key={i} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <Button className="userbutton" onClick={() => this.addUser()}>
                  Submit
                </Button>

                <br />
                <span style={{ color: "red" }}>{this.state.error}</span>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default AddUser;
