import React from "react";
import { Tag, Button, Divider, Space, Tooltip } from "antd";
import { FiEdit2, FiExternalLink, FiTrash2 } from "react-icons/fi";
import moment from "moment";
const CertificationData = (props) => {
  return (
    <div className={"row pb-3"}>
      {props.certificatesList.map((certificate, i) => {
        return (
          <div key={i} className="col-12">
            <div className="flex-grid-secnds">
              <span className="subhead1">{certificate.certificate.name}</span>
              <Space>
                <Tooltip title="Edit Certification Details ">
                  <FiEdit2
                    style={{ cursor: "pointer" }}
                    onClick={() => props.onCertificateEdit(certificate)}
                  />
                </Tooltip>
                <Tooltip title="Remove Certificate">
                  <FiTrash2
                    style={{ cursor: "pointer" }}
                    onClick={() => props.onCertificateDelete(certificate)}
                  />
                </Tooltip>
              </Space>
            </div>
            <div className="head1">{certificate.certificate.organization}</div>
            <div>
              <span>Issued at: </span>
              <span>{moment(certificate.issued_date).format("DD MMM YY")}</span>
              {certificate.has_expiry && (
                <>
                  {" "}
                  | <span>Expiry at: </span>
                  <span>
                    {moment(certificate.expiry_date).format("DD MMM YY")}
                  </span>
                </>
              )}
            </div>
            <div>
              {certificate.credential_id && (
                <Tag className="tag_profile">
                  Credential ID: {certificate.credential_id}
                </Tag>
              )}
              {certificate.credential_url && (
                <Button
                  className="showCred p-0 m-0"
                  type="text"
                  size="small"
                  onClick={() => window.open(certificate.credential_url)}
                >
                  Show Credentials <FiExternalLink className="mb-1 ml-2" />
                </Button>
              )}
            </div>
            <Divider className="my-2" />
          </div>
        );
      })}
    </div>
  );
};

export default CertificationData;
