import {
  SUB_DETAILS,
  SUB_ID
} from "constants/ActionTypes";

const INIT_STATE = {
  marketingDetails:null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUB_DETAILS: {
      return {
        ...state,
        marketingDetails: {...state.marketingDetails,...action.payload},
      };
    }
    case SUB_ID: {
      return {
        ...state,
        marketingDetails: {...state.marketingDetails,...action.payload},
      };
    }
    default:
      return state;
  }
}
