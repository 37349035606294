import React, { Component } from "react";
import { Button, Avatar, Spin, Upload, Tag, Tooltip } from "antd";
import { FiUnlock, FiEdit2 } from "react-icons/fi";
import axiosJSON from "../util/Api";
import DisplayProfile from "./UserProfile/displayProfile";
import EditUserProfile from "./UserProfile/editUserProfile";
import ChangePassword from "./UserProfile/changePassword";
import ImgCrop from "antd-img-crop";
import {
  UploadOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { messageError, messageSuccess } from "../util/AlertMessage";
import AddCertificate from "./UserProfile/addCertificate";
import { CustomModal as Modal, confirm } from "../util/CustomModal";
import { putData } from "../util/trackingAPI";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      display: "profile",
      technologyList: [],
      teamList: [],
      shiftList: [],
      loading: false,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      profileBody: {},
      otherSkill: "",
      certificatesList: [],
      editCertificate: false,
      selectedCertificate: null,
    };
  }

  onRevert = () => {
    this.props.onRevert(
      `/#/reportDetail/${this.state.profile.id}/project_list`
    );
  };

  onChange = ({ fileList: newFileList }) => {
    putData(`image changed for profile`, "log1");
    this.setState({
      fileList: newFileList,
    });
    if (newFileList[0]) {
      this.chnageProfileBody("image", newFileList[0].originFileObj);
    }
  };

  onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  componentDidMount() {
    this.userDetails();
    this.getTechnologyList();
    this.getShiftsList();
    this.getTeamList();
    this.getCertifications();
    this.setState({ display: "profile" });
  }

  userDetails = () => {
    if (localStorage.getItem("current_token")) {
      axiosJSON.defaults.headers["authorization"] =
        "Token " + localStorage.getItem("current_token");
    } else {
      axiosJSON.defaults.headers["authorization"] =
        "Token " + localStorage.getItem("token");
    }

    // const employeeId = localStorage.getItem("current_employee_id");
    // const cookies_string = `${employeeId}_cookies`;
    // axiosJSON.defaults.headers.common["X-Id-Token"] = localStorage.getItem(`${cookies_string}`)

    this.setState({
      loading: true,
    });
    axiosJSON
      .get("/api/employee/me/")
      .then((res) => {
        let {technology}=res.data.data;
        if(technology !== null && technology !== undefined && technology!==""){
           while(technology.includes(null)){
             let index=technology.indexOf(null);
             technology.splice(index,1);
           }
        }
        else{
          technology=[];
        }
        this.setState({ profile: res.data.data, loading: false });
      })
      .catch(function (error) {
        messageError(error);
        this.setState({
          loading: false,
        });
      });
  };

  onCertificateAdd = (value) => {
    this.setState({ loading: true });
    axiosJSON
      .put(`api/employee_certificate/mark_certificate/`, {
        have_certificate: value,
      })
      .then((data) => {
        this.setState({
          loading: false,
        });
        this.userDetails();
        messageSuccess("Data Updated Successfully");
      })
      .catch((error) => {
        messageError(error);
        this.setState({ loading: false });
      });
  };

  getTechnologyList = () => {
    axiosJSON
      .get(`api/utility/technology`)
      .then((data) => {
        this.setState({
          technologyList: data.data.data,
        });
      })
      .catch((error) => {
        messageError(error);
      });
  };

  getShiftsList = () => {
    axiosJSON
      .get(`api/employee/shifts/`)
      .then((data) => {
        let list = [];
        if (data) {
          data.data.data.map((ele) => {
            let obj = {
              value: ele[0],
              display: ele[1],
            };
            list.push(obj);
            return null;
          });
        }
        this.setState({
          shiftList: list,
        });
      })
      .catch((error) => {
        messageError(error);
      });
  };

  getTeamList = () => {
    axiosJSON
      .get(`api/employee/team/`)
      .then((data) => {
        this.setState({
          teamList: data.data.data,
        });
      })
      .catch((error) => {
        messageError(error);
      });
  };

  getCertifications = () => {
    axiosJSON
      .get(`api/employee_certificate/`)
      .then((data) => {
        this.setState({
          certificatesList: data.data.data,
        });
      })
      .catch((error) => {
        messageError(error);
      });
  };

  chnageProfileBody = (key, value) => {
    let profileBody = { ...this.state.profileBody };
    profileBody[key] = value;
    this.setState({ profileBody });
  };

  changePassword = (current_password, password) => {
    putData(`password changed`, "log1");
    const body = {
      cur_password: current_password,
      new_password: password,
    };
    axiosJSON
      .post("/api/employee/change_password/", body)
      .then((res) => {
        if (res.status === 200) {
          messageSuccess(res.data.message);
          this.setState({ display: "profile" });
        }
      })
      .catch(function (error) {
        messageError(error);
      });
  };

  onEditClick = () => {
    putData(`edit profile clicked`, "log1");
    this.getTechnologyList();
    this.setState({ display: "edit_profile" });
  };

  onManagePassword = () => {
    putData(`manage password clicked`, "log1");
    this.setState({ display: "manage_password" });
  };

  getUpdatedSkills = (tech, otherskill) => {
    let oldSkills = [...tech];
    oldSkills.splice(tech.indexOf("Other"), 1);
    if (otherskill) {
      let newSkills = otherskill.split(",").map((item) => item.trim());
      let temp = oldSkills.map((item) => item.toLowerCase());
      newSkills.map((item) => {
        if (temp.indexOf(item) < 0) {
          oldSkills.push(item);
        }
        return null;
      });
      return oldSkills;
    } else return oldSkills;
  };

  onUpdate = () => {
    putData(`user profile update`, "log1");
    this.setState({
      loading: true,
    });
    let formData = new FormData();
    for (let key in this.state.profileBody) {
      if (key === "technology") {
        if (this.state.profileBody[key].indexOf("Other") > -1) {
          formData.append(
            `${key}`,
            JSON.stringify(
              this.getUpdatedSkills(
                this.state.profileBody[key],
                this.state.otherSkill
              )
            )
          );
        } else {
          formData.append(
            `${key}`,
            JSON.stringify(this.state.profileBody[key])
          );
        }
      } else {
        formData.append(`${key}`, this.state.profileBody[key]);
      }
    }
    if (this.state.otherSkill && !this.state.profileBody["technology"]) {
      formData.append(
        `technology`,
        JSON.stringify(
          this.getUpdatedSkills(
            this.state.profile.technology,
            this.state.otherSkill
          )
        )
      );
    }
    let body = {
      technology: this.state.otherSkill.split(",").map((item) => item.trim()),
    };
    Promise.all([
      axiosJSON
        .put("api/employee/update/", formData)
        .then(({ data }) => {
          messageSuccess(data.message);
          this.userDetails();
          this.setState({ display: "profile", loading: false });
        })
        .catch(function (error) {
          messageError(error);
        }),
      this.state.otherSkill &&
        axiosJSON
          .put("api/utility/add_technology/", body)
          .catch(function (error) {
            messageError(error);
          }),
    ]);
    this.setState({
      profileBody: {},
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      otherSkill: "",
    });
  };

  onCancel = (e) => {
    this.setState({ display: "profile" });
  };

  onOtherSkillChange = (val) => {
    putData(`other skill value = { ${val} }`, "log1");
    this.setState({ otherSkill: val });
  };

  onCertificateEdit = (val) => {
    putData(`certificate edit value = { ${val} }`, "log1");
    this.setState({
      editCertificate: true,
      selectedCertificate: val,
    });
  };

  onCertificateDelete = (val) => {
    putData(`certificate delete = { ${val} }`, "log1");
    let self = this;
    confirm({
      title: (
        <div className="font-weight-normal">
          Are you sure you want to Remove{" "}
          <span className="font-weight-bold">{val.certificate.name}</span> ?
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        axiosJSON
          .delete(`/api/employee_certificate/${val.id}/`)
          .then((res) => {
            messageSuccess("Certificate removed Successfully");
            self.getCertifications();
          })
          .catch((err) => {
            messageError(err);
          });
      },
    });
  };

  closeModal = () => {
    putData(`modal closed`, "log1");
    this.setState(
      {
        editCertificate: false,
        selectedCertificate: null,
      },
      () => this.getCertifications()
    );
  };

  genExtra = () => (
    <Tooltip title="Add Certificate">
      <PlusOutlined
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          this.onCertificateEdit();
          event.stopPropagation();
        }}
      />
    </Tooltip>
  );

  render() {
    return (
      <div className={"col-12 pb-4 profile_correction"}>
        <Spin spinning={this.state.loading}>
          <div className={"row mx-0 center_align pt-3 pb-4 borderBottom"}>
            <div className={"col-6"}>
              <div className={"row"}>
                <div className={"center_self"}>
                  {this.state.display !== "edit_profile" ? (
                    <>
                      {Object.entries(this.state.profile).length > 0 && (
                        <Avatar
                          size={40}
                          style={{
                            color: "#555F8B",
                            backgroundColor: "#F1F3FA",
                          }}
                          src={this.state.profile.avatar}
                        >
                          {this.state.profile.employee_name.split(" ")[1]
                            ? this.state.profile.employee_name
                                .split(" ")[0]
                                .charAt(0)
                                .toUpperCase() +
                              this.state.profile.employee_name
                                .split(" ")[1]
                                .charAt(0)
                                .toUpperCase()
                            : this.state.profile.employee_name
                                .split(" ")[0]
                                .charAt(0)
                                .toUpperCase()}
                        </Avatar>
                      )}
                    </>
                  ) : (
                    <div>
                      <ImgCrop rotate>
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          fileList={this.state.fileList}
                          onChange={this.onChange}
                          onPreview={this.onPreview}
                        >
                          {this.state.fileList.length < 1 && <UploadOutlined />}
                        </Upload>
                      </ImgCrop>
                      <Modal
                        visible={this.state.previewVisible}
                        title={this.state.previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={this.state.previewImage}
                        />
                      </Modal>
                    </div>
                  )}
                </div>
                <div className={"col-8 center_self"}>
                  <div className="edit_name fnt_500">
                    <Tag className="tag_profile">
                      <span>Employee ID :</span>
                      {this.state.profile.employee_id}
                    </Tag>
                  </div>
                  {this.state.display !== "edit_profile" && (
                    <div className="edit_name fnt_500">
                      {this.state.profile.employee_name}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={"col-6"}>
              <Button
                disabled={this.state.display === "manage_password"}
                onClick={this.onManagePassword}
                className="mb-0 button_outline"
              >
                <FiUnlock className="icon_model" /> Manage Password
              </Button>

              <Button
                disabled={this.state.display === "edit_profile"}
                onClick={this.onEditClick}
                className="mb-0 button_ri"
              >
                <FiEdit2 className="icon_model" />
                Edit
              </Button>
            </div>
          </div>
          {/*TODO: Display Horizontal line*/}
          <div>
            {this.state.display === "profile" ? (
              <DisplayProfile
                profileData={this.state.profile}
                shiftList={this.state.shiftList}
                certificatesList={this.state.certificatesList}
                onRevert={this.onRevert}
                genExtra={this.genExtra}
                onCertificateDelete={this.onCertificateDelete}
                onCertificateEdit={this.onCertificateEdit}
                onCertificateAdd={this.onCertificateAdd}
              />
            ) : this.state.display === "edit_profile" ? (
              <EditUserProfile
                selectedTechnology={this.state.profile.technology}
                shiftList={this.state.shiftList}
                teamList={this.state.teamList}
                chnageProfileBody={this.chnageProfileBody}
                technologyList={this.state.technologyList}
                profile={this.state.profile}
                onUpdate={this.onUpdate}
                onCancel={this.onCancel}
                onOtherSkillChange={this.onOtherSkillChange}
                otherSkill={this.state.otherSkill}
                // certificatesList={this.state.certificatesList}
                // onCertificateEdit={this.onCertificateEdit}
                // genExtra={this.genExtra}
              />
            ) : (
              <ChangePassword
                changePassword={this.changePassword}
                onCancel={this.onCancel}
              />
            )}
          </div>
        </Spin>
        {this.state.editCertificate && (
          <Modal
            title="Add Certification Details"
            footer={null}
            width={600}
            onCancel={() => this.setState({ editCertificate: false })}
            visible={this.state.editCertificate}
          >
            <AddCertificate
              certificate={this.state.selectedCertificate}
              closeModal={this.closeModal}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default EditProfile;
