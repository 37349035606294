import firebase from "firebase";

// Initialize Firebase
export const config = {
  apiKey: "AIzaSyAC1MXLjIIDnLhYLKdUWoGx3tKrox1pA9I",
  authDomain: "consultadd-timesheet.firebaseapp.com",
  databaseURL: "https://consultadd-timesheet.firebaseio.com/",
  projectId: "consultadd-timesheet",
  storageBucket: "consultadd-timesheet.appspot.com",
  messagingSenderId: "678828580856",
  appId: "1:678828580856:web:2aca5346b74d5551b3800d",
  measurementId: "G-GWXE2R0L85"
};

firebase.initializeApp(config);

export default firebase;
