import { Button, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { messageError } from "../../../../util/AlertMessage";
import axiosJSON from "../../../../util/Api";
// import { BsArrowRightCircleFill } from "react-icons/bs";

export const APIcomponent = (props) => {
  const [codingQuestion, setCodingQuestion] = useState(1);
  const [newQuestionSet, setNewQuestionSet] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeInput = (value) => {
    if (value === 0 || value === "") {
      setCodingQuestion(value);
      setNewQuestionSet([]);
      props.handleFieldChange(
        value,
        props.fieldObj,
        null,
        null,
        props.parentId,
        true,
        true
      );
    } else if (parseInt(value) > 0 && parseInt(value) < 16) {
      setCodingQuestion(parseInt(value));
      props.setEnabled(true);
      props.handleFieldChange(
        parseInt(value),
        props.fieldObj,
        null,
        null,
        props.parentId,
        props.apiQuestion
      );
    }
  };

  useEffect(() => {
    props.handleFieldChange(
      codingQuestion,
      props.fieldObj,
      null,
      null,
      props.parentId,
      props.apiQuestion
    );
    onClickHandel();
    // eslint-disable-next-line
  }, []);

  const onClickHandel = () => {
    let questionSet = [...props.testFields];
    props.setEnabled(false);
    setLoading(true);
    axiosJSON
      .get(`/api/question/${props.fieldObj.id}/parent/?value=${codingQuestion}`)
      .then((res) => {
        setNewQuestionSet(
          res.data.data.map((item) => ({
            ...item,
            parent_id: props.fieldObj.id,
          }))
        );
        setLoading(false);
        res.data.data.map((question) => {
          if (
            question.category === "parent" &&
            question.answer_type === "headline"
          ) {
            question.child.map((childQuestion) => {
              let isThere = questionSet.findIndex(
                (ele) => ele.id === childQuestion.id && ele.pId === question.id
              );
              if (isThere === -1) {
                let newObj = { ...childQuestion };
                newObj["pId"] = question.id;
                newObj["noRender"] = true;
                newObj["values"] = null;
                questionSet.push(newObj);
              }
              return null;
            });
          } else {
            //if formate is changed
          }
          props.setTestFields(
            questionSet,
            res.data.data.map((item) => ({
              ...item,
              parent_id: props.fieldObj.id,
            }))
          );
          return null;
        });
      })
      .catch((error) => {
        messageError(error);
        setLoading(false);
      });
  };

  return (
    <div style={{ marginTop: "10px" }} className="some_test">
      <label className="largeFont">
        {props.fieldObj.is_required && (
          <span style={{ color: "red", fontSize: 15 }}>*</span>
        )}
        {props.fieldObj.title} :
      </label>
      <Input
        placeholder={props.fieldObj.title}
        id={props.id}
        value={codingQuestion}
        min={props.fieldObj.answer_type === "integer" ? 1 : null}
        max={props.fieldObj.answer_type === "integer" ? 15 : null}
        type={props.fieldObj.answer_type === "integer" ? "numeric" : null}
        onChange={(e) => onChangeInput(e.target.value)}
      />
      {codingQuestion && (
        <Button
          style={{
            position: "absolute",
            fontSize: "14",
            backgraoundColor: "#24305e",
            cursor: "pointer",
            marginLeft: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          onClick={() => {
            onClickHandel();
          }}
        >
          Proceed
        </Button>
      )}
      {props.fieldObj.answer_type === "integer" && (
        <div style={{ marginTop: "-10px", color: "orange" }}>
          Please enter value between 1 to 15.
        </div>
      )}
      <Spin spinning={loading}>
        {codingQuestion &&
          newQuestionSet &&
          newQuestionSet.map((testcase) => {
            if (
              testcase.category === "parent" &&
              testcase.answer_type === "headline"
            ) {
              return (
                <div
                  className="question_wrapper mt-3"
                >
                  <h3>{testcase && testcase.title}</h3>
                  {testcase &&
                    testcase.child &&
                    testcase.child.map((element) => {
                      return props.renderFormComponent(
                        element,
                        testcase.id,
                        true
                      );
                    })}
                </div>
              );
            } else {
              //for future case
              props.renderFormComponent(testcase);
              return null;
            }
          })}
      </Spin>
    </div>
  );
};

export default APIcomponent;
