import React, { useEffect, useState } from "react";
import { Slider, Tag } from "antd";
import { FiCheck, FiSlash } from "react-icons/fi";
import { BsQuestion } from "react-icons/bs";

const SliderComponent = (props) => {
  const [inputVal, setInputVal] = useState(props.fieldObj.values);

  useEffect(() => {
    if (props.answer && props.answer.length > 0) {
      let an = props.answer.filter(
        (answer) => answer.question_answer.ques_id === props.fieldObj.id
      )[0];
      let answer = an
        ? an.question_answer.answer
        : props.fieldObj.values
        ? props.fieldObj.values
        : null;
      setInputVal(answer);
    }
    // eslint-disable-next-line
  }, []);

  const getText = (val) => {
    switch (true) {
      case val > 7:
        return {
          name: "Offer",
          color: "#28A745",
          icon: <FiCheck />,
          class: "rating-slider-green",
        };
      case val > 5 && val <= 7:
        return {
          name: "Maybe",
          color: "#E8930B",
          icon: <BsQuestion style={{ fontSize: "20px" }} />,
          class: "rating-slider-yellow",
        };
      default:
        return {
          name: "Reject",
          color: "#EF4444",
          icon: <FiSlash />,
          class: "rating-slider-red",
        };
    }
  };
  const obj = getText(inputVal);
  return (
    <div className="mt-2">
      <label className="largeFont">
        {props.fieldObj.is_required && (
          <span style={{ color: "red", fontSize: 15 }}>*</span>
        )}
        {props.fieldObj.title} :
      </label>
      <div className={`rating-slider ${obj.class}`}>
        {inputVal && (
          <div>
            <Tag>
              {obj.icon} {obj.name} - {inputVal}
            </Tag>
          </div>
        )}
        <Slider
          min={1}
          max={10}
          step={1}
          dots={true}
          marks={{
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
          }}
          tooltip={{ open: true }}
          value={inputVal}
          onChange={(value) => {
            setInputVal(value);
            props.handleFieldChange(
              value,
              props.fieldObj,
              null,
              null,
              props.parentId,
              props.apiQuestion
            );
          }}
        />
      </div>
    </div>
  );
};

export default SliderComponent;
