import {CON_STATUS, CON_SUB_STATUS} from "../../constants/ActionTypes";

export const setConStatus = (data) => {
  return (dispatch) => {
    dispatch({type: CON_STATUS, payload: data});
  }
};
export const setConSubStatus = (data) => {
  return (dispatch) => {
    dispatch({type: CON_SUB_STATUS, payload: data});
  }
};
