import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  RiUserStarLine,
  RiUserSearchLine,
  RiUserSettingsLine,
  RiFolderReceivedLine,
  RiFileUserFill,
  RiMailFill,
} from "react-icons/ri";
import { MdAssessment, MdBusinessCenter, MdPayments } from "react-icons/md";
import { IoIosAnalytics } from "react-icons/io";
import {
  FaUserGraduate,
  FaUserLock,
  FaUsers,
  FaUsersCog,
  // FaUsersCog,
} from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import { GoDashboard, GoFileDirectory } from "react-icons/go";
import {
  BsCalendarDate,
  BsFillCalendarWeekFill,
  BsFillFileEarmarkSpreadsheetFill,
} from "react-icons/bs";
import {
  FaRobot,
  FaUserSecret,
  FaTools,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import { BiDollarCircle, BiMessageRoundedDetail } from "react-icons/bi";
import {
  setActiveKeys,
  setConsultantSortBy,
  setFilterByRegion,
  setFilterFor,
  setFilters,
  setPageNo,
  setSearch,
  setSortBy,
} from "../../appRedux/actions";
import { putData } from "../../util/trackingAPI";
import { FiDollarSign } from "react-icons/fi";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const dispatch = useDispatch();
  const getSelectedKey = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const { authUser, newUser } = useSelector(({ auth }) => auth);
  // const pathname = useSelector(({ settings }) => settings.pathname);
  const [selectedKeys, setSelectedKey] = useState(
    getSelectedKey(window.location.href)
  );
  let currentUserRole = "";
  let currentToken = localStorage.getItem("current_token");
  currentUserRole = currentToken ? newUser?.roles : authUser?.roles;

  const checkRole = (role, type = "engineer") => {
    return (
      role &&
      role.includes(type) &&
      (role.includes("scrum_master") ||
        role.includes("admin") ||
        role.includes("superadmin"))
    );
  };

  const clearFilters = () => {
    dispatch(setFilters({}));
    dispatch(setSearch(""));
    dispatch(setFilterFor("all"));
    dispatch(setFilterByRegion(""));
    dispatch(setPageNo(1));
    dispatch(setSortBy("modified"));
    dispatch(setConsultantSortBy("-created"));
  };

  return (
    <Menu selectedKeys={[selectedKeys]} mode="horizontal">
      <Menu.Item
        key="dashboard"
        onClick={() => {
          setSelectedKey("dashboard");
          putData(`redirect to { Dashboard } in dashboard`, "log1");
        }}
      >
        <Link to="/dashboard">
          <GoDashboard style={{ fontSize: "18px", marginRight: "5px" }} />
          <IntlMessages id="sidebar.dashboard" />
        </Link>
      </Menu.Item>
      <SubMenu
        key="marketing"
        title={
          <div>
            <MdBusinessCenter
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.marketing" />
          </div>
        }
      >
        <Menu.Item
          key="lead"
          onClick={() => {
            setSelectedKey("lead");
            putData(`redirect to { Requirements } in marketing`, "log1");
          }}
        >
          <Link to="/marketing/lead" onClick={clearFilters}>
            <RiUserSearchLine
              style={{ fontSize: "18px", marginRight: "5px" }}
            />

            <IntlMessages id="sidebar.marketing.requirements" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="submission"
          onClick={() => {
            setSelectedKey("submission");
            putData(`redirect to { Submissions } in marketing`, "log1");
          }}
        >
          <Link to="/marketing/submission" onClick={clearFilters}>
            {" "}
            <RiUserStarLine style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.submissions" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="test"
          onClick={() => {
            setSelectedKey("test");
            putData(`redirect to { Tests } in marketing`, "log1");
          }}
        >
          <Link to="/marketing/test" onClick={clearFilters}>
            <MdAssessment style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.tests" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="interview"
          onClick={() => {
            setSelectedKey("interview");
            putData(`redirect to { Interviews} in marketing`, "log1");
          }}
        >
          <Link to="/marketing/interview" onClick={clearFilters}>
            <AiOutlineSchedule
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.marketing.interviews" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="po"
          onClick={() => {
            setSelectedKey("po");
            dispatch(setActiveKeys(["1", "2", "3"]));
            putData(`redirect to { Purchase Orders } in marketing`, "log1");
          }}
        >
          <Link to="/marketing/po" onClick={clearFilters}>
            <FaUserLock style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.pos" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="profiles"
          onClick={() => {
            setSelectedKey("profiles");
            putData(`redirect to { Profiles } in marketing`, "log1");
          }}
        >
          <Link to="/marketing/profiles" onClick={clearFilters}>
            <FaUserGraduate style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.profiles" />
          </Link>
        </Menu.Item>
        {currentUserRole && !currentUserRole.includes("usa_employee") && (
          <Menu.Item
            key="reports"
            onClick={() => {
              setSelectedKey("reports");
              putData(`redirect to { Reports } in marketing`, "log1");
            }}
          >
            <Link to="/marketing/reports" onClick={clearFilters}>
              <IoIosAnalytics
                style={{ fontSize: "18px", marginRight: "5px" }}
              />
              <IntlMessages id="sidebar.marketing.reports" />
            </Link>
          </Menu.Item>
        )}
        {checkRole(currentUserRole, "marketer") && (
          <Menu.Item
            key="marketing_team"
            onClick={() => {
              setSelectedKey("marketing_team");
              dispatch(setFilters({}));
              dispatch(setSearch(""));
              dispatch(setFilterFor("all"));
              putData(`redirect to { Teams Detail } in marketing`, "log1");
            }}
          >
            <Link to="/marketing/marketing_team">
              <FaUsers style={{ fontSize: "18px", marginRight: "5px" }} />
              <IntlMessages id="sidebar.marketing.teamsDetail" />
            </Link>
          </Menu.Item>
        )}
        {currentUserRole &&
          (currentUserRole.includes("superadmin") ||
            currentUserRole.includes("finance") ||
            (currentUserRole.includes("marketer") &&
              currentUserRole.includes("admin")) ||
            (currentUserRole.includes("marketer") &&
              currentUserRole.includes("scrum_master"))) && (
            <Menu.Item
              key="remote-projects"
              onClick={() => {
                setSelectedKey("remote-projects");
                putData(`redirect to { Remote Projects } in marketing`, "log1");
              }}
            >
              <Link to="/marketing/remote-projects">
                <FaRobot style={{ fontSize: "18px", marginRight: "5px" }} />
                <IntlMessages id="sidebar.marketing.remote" />
              </Link>
            </Menu.Item>
          )}
      </SubMenu>
      {currentUserRole && !currentUserRole.includes("usa_employee") && (
        <Menu.Item
          key="bench"
          onClick={() => {
            setSelectedKey("bench");
            putData(`redirect to { Consultant } in consultant`, "log1");
          }}
        >
          <Link to="/consultant/bench" onClick={clearFilters}>
            <RiUserSettingsLine
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.consultant" />
          </Link>
        </Menu.Item>
      )}
      {currentUserRole &&
        (currentUserRole.includes("engineer") ||
          currentUserRole.includes("superadmin")) && (
          <SubMenu
            key="engineering"
            title={
              <div>
                <FaRobot style={{ fontSize: "18px", marginRight: "5px" }} />
                <IntlMessages id="sidebar.engineering" />
              </div>
            }
          >
            <Menu.Item
              key="engineering_module"
              onClick={() => {
                setSelectedKey("engineering_module");
                putData(`redirect to { Projects } in engineering`, "log1");
              }}
            >
              <Link to="/engineering_module" onClick={clearFilters}>
                <FaRobot style={{ fontSize: "18px", marginRight: "5px" }} />
                Projects
              </Link>
            </Menu.Item>
            <Menu.Item
              key="engineering"
              onClick={() => {
                setSelectedKey("engineering");
                putData(`redirect to { Reports } in engineering`, "log1");
              }}
            >
              <Link to="/engineering">
                <IoIosAnalytics
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                Reports
              </Link>
            </Menu.Item>
            {
              <Menu.Item
                key="engineering_report"
                onClick={() => {
                  setSelectedKey("engineering_report");
                  putData(
                    `redirect to { Engineers Detail } in engineering`,
                    "log1"
                  );
                }}
              >
                <Link to="/engineering_report">
                  <FaUserSecret
                    style={{ fontSize: "18px", marginRight: "5px" }}
                  />
                  Engineers Detail
                </Link>
              </Menu.Item>
            }
            {checkRole(currentUserRole) && (
              <Menu.Item
                key="engineering_team"
                onClick={() => {
                  setSelectedKey("engineering_team");
                  putData(
                    `redirect to { Teams Detail } in engineering`,
                    "log1"
                  );
                }}
              >
                <Link to="/engineering_team">
                  <FaUsers style={{ fontSize: "18px", marginRight: "5px" }} />
                  Teams Detail
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
      {/*{*/}
      {/*  (currentUserRole.includes("engineer") || currentUserRole.includes("superadmin")) &&*/}
      {/*  <Menu.Item key="engineering">*/}
      {/*    <Link to="/engineering">*/}
      {/*      <FaRobot style={{fontSize: '18px', marginRight: '5px'}}/>*/}

      {/*      <IntlMessages id="sidebar.engineering"/></Link>*/}
      {/*  </Menu.Item>*/}
      {/*}*/}
      {/*{*/}
      {/*  (currentUserRole.includes("engineer") || currentUserRole.includes("superadmin")) &&*/}
      {/*  <Menu.Item key="engineering_module">*/}
      {/*    <Link to="/engineering_module" onClick={()=> {*/}
      {/*      dispatch(setFilters({}))*/}
      {/*      dispatch(setSearch(""))*/}
      {/*      dispatch(setFilterFor("all"))*/}
      {/*    }}>*/}
      {/*      <FaRobot style={{fontSize: '18px', marginRight: '5px'}}/>*/}

      {/*      Engineering Module</Link>*/}
      {/*  </Menu.Item>*/}
      {/*}*/}
      {currentUserRole &&
        (currentUserRole.includes("finance") ||
          currentUserRole.includes("retention") ||
          currentUserRole.includes("superadmin")) && (
          <SubMenu
            key="finance"
            title={
              <div>
                <FaMoneyCheckAlt
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                <IntlMessages id="sidebar.finance" />
              </div>
            }
          >
            {/* {currentUserRole &&
              (currentUserRole.includes("finance") ||
                currentUserRole.includes("superadmin")) && (
                <Menu.Item
                  key="finance"
                  onClick={() => {
                    putData(`redirect to { Finance } in finance`, "log1");
                  }}
                >
                  <Link to="/finance">
                    <FaMoneyCheckAlt
                      style={{ fontSize: "18px", marginRight: "5px" }}
                    />
                    <IntlMessages id="sidebar.finance" />
                  </Link>
                </Menu.Item>
              )}
            
                <Menu.Item
                  key="leave_managment"
                  onClick={() => {
                    putData(
                      `redirect to { Leave Managment } in finance`,
                      "log1"
                    );
                  }}
                >
                  <Link to="/finance/leave_management">
                    <FaUserSecret
                      style={{ fontSize: "18px", marginRight: "5px" }}
                    />
                    Leave Managment
                  </Link>
                </Menu.Item> */}

            {currentUserRole &&
              (currentUserRole.includes("finance") ||
                currentUserRole.includes("superadmin")) && (
                <Menu.Item
                  key="timesheet"
                  onClick={() => {
                    setSelectedKey("timesheet");
                    putData(`redirect to { Timesheet } in finance`, "log1");
                  }}
                >
                  <Link to="/finance/timesheet">
                    <BsFillFileEarmarkSpreadsheetFill
                      style={{ fontSize: "18px", marginRight: "5px" }}
                    />
                    Timesheet
                  </Link>
                </Menu.Item>
              )}
            {currentUserRole &&
              (currentUserRole.includes("finance") ||
                currentUserRole.includes("superadmin")) && (
                <Menu.Item
                  key="paystub"
                  onClick={() => {
                    setSelectedKey("paystub");
                    putData(`redirect to { PayStub } in finance`, "log1");
                  }}
                >
                  <Link to="/finance/paystub">
                    <MdPayments
                      style={{ fontSize: "18px", marginRight: "5px" }}
                    />
                    PayStub
                  </Link>
                </Menu.Item>
              )}
            {currentUserRole &&
              (currentUserRole.includes("finance") ||
                currentUserRole.includes("superadmin")) && (
                <Menu.Item
                  key="leave-management"
                  onClick={() => {
                    setSelectedKey("leave-management");
                    putData(
                      `redirect to { Leave Management } in finance`,
                      "log1"
                    );
                  }}
                >
                  <Link to="/finance/leave-management">
                    <BsFillCalendarWeekFill
                      style={{ fontSize: "18px", marginRight: "5px" }}
                    />
                    Leave Management
                  </Link>
                </Menu.Item>
              )}
            {currentUserRole &&
              (currentUserRole.includes("finance") ||
                currentUserRole.includes("retention") ||
                currentUserRole.includes("superadmin")) && (
                <Menu.Item
                  key="rate_revision"
                  onClick={() => {
                    setSelectedKey("rate_revision");
                    putData(`redirect to { Rate Revision } in finance`, "log1");
                  }}
                >
                  <Link to="/finance/rate_revision">
                    <BiDollarCircle
                      style={{ fontSize: "18px", marginRight: "5px" }}
                    />
                    Rate Revision
                  </Link>
                </Menu.Item>
              )}
          </SubMenu>
        )}
      {currentUserRole &&
        (currentUserRole.includes("legal") ||
          currentUserRole.includes("superadmin")) && (
          <Menu.Item
            key="legal"
            onClick={() => {
              setSelectedKey("legal");
              putData(`redirect to { Legal } in legal`, "log1");
            }}
          >
            <Link
              to="/legal"
              onClick={() => {
                dispatch(setSearch(""));
                dispatch(setFilterFor("my"));
              }}
            >
              <FaUserSecret style={{ fontSize: "18px", marginRight: "5px" }} />

              <IntlMessages id="sidebar.legal" />
            </Link>
          </Menu.Item>
        )}
      {currentUserRole && !currentUserRole.includes("usa_employee") && (
        <SubMenu
          key="utility"
          title={
            <div>
              <FaTools style={{ fontSize: "18px", marginRight: "5px" }} />

              <IntlMessages id="sidebar.utility" />
            </div>
          }
        >
          <Menu.Item
            key="calendar"
            onClick={() => {
              setSelectedKey("calendar");
              putData(`redirect to { Calendar } in legal`, "log1");
            }}
          >
            <Link to="/utility/calendar">
              <BsCalendarDate
                style={{ fontSize: "18px", marginRight: "5px" }}
              />
              <IntlMessages id="Calendar" />
            </Link>
          </Menu.Item>

          <Menu.Item
            key="messaging"
            onClick={() => {
              setSelectedKey("messaging");
              putData(`redirect to { Messaging } in utilities`, "log1");
            }}
          >
            <Link to="/utility/messaging">
              <BiMessageRoundedDetail
                style={{ fontSize: "18px", marginRight: "5px" }}
              />
              <IntlMessages id="sidebar.utility.messaging" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="assets"
            onClick={() => {
              setSelectedKey("assets");
              putData(`redirect to { Assets } in utilities`, "log1");
            }}
          >
            <Link to="/utility/assets">
              <GoFileDirectory
                style={{ fontSize: "18px", marginRight: "5px" }}
              />

              <IntlMessages id="sidebar.utility.assets" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="oldsubmission"
            onClick={() => {
              setSelectedKey("oldsubmission");
              putData(`redirect to { Old Submissions } in utilities`, "log1");
            }}
          >
            <Link to="/utility/oldsubmission">
              <RiFolderReceivedLine
                style={{ fontSize: "18px", marginRight: "5px" }}
              />
              <IntlMessages id="sidebar.utility.old_submissions" />
            </Link>
          </Menu.Item>
          {currentUserRole && currentUserRole.includes("superadmin") && (
            <Menu.Item
              key="userDirectory"
              onClick={() => {
                setSelectedKey("userDirectory");
                putData(`redirect to { User Directory } in utilities`, "log1");
              }}
            >
              <Link to="/utility/userDirectory">
                <RiFileUserFill
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                <IntlMessages id="sidebar.utility.userDirectory" />
              </Link>
            </Menu.Item>
          )}
          <Menu.Item
            key="timesheetEvents"
            onClick={() => {
              setSelectedKey("timesheetEvents");
              putData(`redirect to { Timesheet Events } in utilities`, "log1");
            }}
          >
            <Link to="/utility/timesheetEvents">
              <RiFolderReceivedLine
                style={{ fontSize: "18px", marginRight: "5px" }}
              />
              <IntlMessages id="sidebar.utility.timesheetEvents" />
            </Link>
          </Menu.Item>

          {currentUserRole && currentUserRole.includes("hod") && (
            <Menu.Item
              key="userActivity"
              onClick={() => {
                setSelectedKey("userActivity");
                putData(`redirect to { User Activity } in utilities`, "log1");
              }}
            >
              <Link to="/utility/userActivity">
                <FaUsersCog
                  style={{ paddingRight: "10px", fontSize: "28px" }}
                />
                <IntlMessages id="sidebar.utility.useractivity" />
              </Link>
            </Menu.Item>
          )}
          {currentUserRole && currentUserRole.includes("superadmin") && (
            <Menu.Item
              key="marketingmails"
              onClick={() => {
                setSelectedKey("marketingmails");
                putData(`redirect to { Marketin Gmails } in utilities`, "log1");
              }}
            >
              <Link to="/utility/marketingmails">
                <RiMailFill style={{ fontSize: "18px", marginRight: "5px" }} />
                <IntlMessages id="sidebar.utility.marketing_mails" />
              </Link>
            </Menu.Item>
          )}
          <Menu.Item
            key="incentives"
            onClick={() => {
              setSelectedKey("incentives");
              putData(
                `redirect to { Project incentives } in utilities`,
                "log1"
              );
            }}
          >
            <Link to="/utility/incentives">
              <FiDollarSign style={{ fontSize: "18px", marginRight: "5px" }} />
              <IntlMessages id="sidebar.marketing.incentives" />
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default React.memo(HorizontalNav);
