import React, { useState, useEffect } from "react";
import { Radio } from "antd";

const BooleanComponent = (props) => {
  const [inputVal, setInputVal] = useState(props.fieldObj.values);

  useEffect(() => {
    if (props.answer && props.answer.length > 0) {
      let an = props.answer.filter(
        (answer) => answer.question_answer.ques_id === props.fieldObj.id
      )[0];
      let answer = an
        ? an.question_answer.answer === "Yes"
          ? "Yes"
          : "No"
        : props.fieldObj.values;
      setInputVal(answer);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="radio-margin">
      <label className="largeFont">
        {props.fieldObj.is_required && (
          <span style={{ color: "red", fontSize: 15 }}>*</span>
        )}
        {props.fieldObj.title} :
      </label>
      <div className="radio">
        <Radio.Group
          value={inputVal}
          onChange={(e) => {
            setInputVal(e.target.value);
            props.handleFieldChange(
              e.target.value,
              props.fieldObj,
              null,
              null,
              props.parentId,
              props.apiQuestion
            );
          }}
        >
          <Radio value={"Yes"}>Yes</Radio>
          <Radio value={"No"}>No</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default BooleanComponent;
