import {
  CON_STATUS,
  CON_SUB_STATUS
} from 'constants/ActionTypes';

const INIT_STATE = {
  status:'',
  sub_status:''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CON_STATUS: {
      return {...state, status:action.payload};
    }
    case CON_SUB_STATUS: {
      return {...state, sub_status:action.payload};
    }
    default:
      return state;
  }
}
