import { Popconfirm, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import axiosJSON from "../../../util/Api";
import RenderQuestionFields from "../../renderQuestionFields";
import { messageError, messageSuccess } from "../../../util/AlertMessage";
const CallSupervisorFeedback = (props) => {
  const [testFields, setTestFields] = useState([]);
  const [testFieldsAnswer, setTestFieldsAnswer] = useState([]);
  const [attachmentsId, setAttachmentId] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFields();
    // eslint-disable-next-line
  }, []);

  const getFields = () => {
    setLoading(true);
    axiosJSON
      .get(`/api/question/?form_name=interview`)
      .then((data) => {
        if (data && data.data) {
          setTestFields(data.data.data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        messageError(error);
        setLoading(false);
      });
  };

  const removeWrongChild = (testFieldsAnswer) => {
    let newAnswerArray = testFieldsAnswer;
    let removeAnswer = [];
    testFieldsAnswer.map((ele) => {
      if (ele.dependent && ele.answer === "No") {
        ele.dependent.map((ele) => removeAnswer.push(ele));
      }
      return null;
    });
    removeAnswer.map((id) => {
      newAnswerArray = newAnswerArray.filter((ele) => ele.question_id !== id);
      return null;
    });
    return newAnswerArray;
  };

  const handleSubmit = () => {
    checkParentChildFields(testFieldsAnswer);
    // let finalAnswerList = removeWrongChild(testFieldsAnswer);
    let conditonalCheck = checkConditionalFields();
    let check = checkMandatoryFields(testFields);
    if (!check || !conditonalCheck) {
      messageError("Please fill all Mandatory fields");
    } else {
      let finalAnswerList = removeWrongChild(testFieldsAnswer);
      const formData = new FormData();
      formData.append("feedback_form", JSON.stringify(finalAnswerList));
      attachmentsId.map((ele) => {
        let listOfAttachments = attachments.find((att) => att.id === ele.id);
        let files = [];
        listOfAttachments.files.forEach((file) => {
          files.push(file.originFileObj);
        });

        if (listOfAttachments.parent) {
          files.forEach((item) => {
            formData.append(`${ele.id}-${ele.parent}`, item);
          });
        } else {
          files.forEach((item) => {
            formData.append(`${ele.id}`, item);
          });
        }
        return null;
      });
      setLoading(true);
      if (!props.edit) {
        axiosJSON
          .post(
            `/api/interview/${props.interview.interview_id}/supervisor_feedback/`,
            formData
          )
          .then((res) => {
            messageSuccess(res.data?.message);
            setLoading(false);
            props.handleClose();
          })
          .catch(function (error) {
            messageError(error);
            setLoading(false);
            props.handleClose();
          });
      } else {
        setLoading(true);
        axiosJSON
          .put(
            `/api/interview/${props.interviewId}/supervisor_feedback/`,
            formData
          )
          .then((res) => {
            messageSuccess(res.data?.message);
            setLoading(false);
            props.handleClose();
          })
          .catch(function (error) {
            messageError(error);
            setLoading(false);
            props.handleClose();
          });
      }
    }
  };

  //method to remove dependent question's answer if parent question answer is No
  const checkParentChildFields = (testFieldsAnswer) => {
    testFieldsAnswer.forEach((item, i) => {
      if (item.parent_question_id) {
        let parentAnswer = testFieldsAnswer.find(
          (answer) => answer.question_id === item.parent_question_id
        );
        if (parentAnswer.answer === "No") {
          testFieldsAnswer.splice(i, 1);
        }
      }
    });
    setTestFieldsAnswer(testFieldsAnswer);
  };

  //method to also check mandatory for dependents questions
  const checkMandatoryFields = (quesFields) => {
    quesFields.forEach((test) => {
      if (test.dependent && test.dependent.length > 0) {
        let answer = testFieldsAnswer.find(
          (answer) => answer.question_id === test.id
        );
        if (answer && answer.answer === "Yes") {
          quesFields = [...quesFields, ...test.dependent];
        }
      }
    });
    return checkMandatory(quesFields);
  };

  //check mandatory of each question
  const checkMandatory = (quesFields) => {
    let check = quesFields.every((test) => {
      if (test.is_required) {
        let answer = testFieldsAnswer.find(
          (answer) => answer.question_id === test.id
        );
        if (!answer) {
          return false;
        } else if (
          answer.answer === null ||
          answer.answer === undefined ||
          answer.answer === ""
        ) {
          return false;
        } else return true;
      } else return true;
    });
    return check;
  };

  const checkConditionalFields = () => {
    let check = false;
    check = testFieldsAnswer.some((item) => {
      if (
        item.que_value === "Issues faced during call" ||
        item.que_value === "What went well in call"
      ) {
        if (item.answer) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    });
    return check;
  };

  return (
    <div className="supervisorModel">
      {/* {console.table(testFieldsAnswer)} */}
      <div className="py-3">
        <label className="largeFont">Call given by</label>
        <div className="text-disabled">
          {props?.interview?.supervisor_detail?.call_given_by
            ? props.interview.supervisor_detail.call_given_by
            : "NA"}
        </div>
      </div>
      <div className="py-3">
        <label className="largeFont">Call supervisor</label>
        <div className="text-disabled">
          {props?.interview?.supervisor_detail?.supervisor_name
            ? props.interview.supervisor_detail.supervisor_name
            : "NA"}
        </div>
      </div>
      <Spin
        style={{ alignItems: "center" }}
        tip="Loading..."
        spinning={loading}
      >
        <div className="off-on_Wrapper py-3 ">
          {testFields && testFields.length > 0 && (
            <RenderQuestionFields
              testFields={testFields}
              setTestFieldsAnswer={setTestFieldsAnswer}
              setAttachmentId={setAttachmentId}
              setAttachments={setAttachments}
              testFieldsAnswer={props.supervisorFeedback}
              edit={props.edit}
            />
          )}
        </div>
      </Spin>

      <div style={{ overflow: "hidden" }}>
        <Button
          onClick={handleSubmit}
          style={{
            marginTop: "10px",
          }}
          //disabled={this.state.disableSubmit}
        >
          Submit
        </Button>
        <Popconfirm
          title="Are you sure to Cancel(All filled data will get erased)?"
          okText="Yes"
          cancelText="No"
          onConfirm={props.handleCancel}
        >
          <Button
            style={{
              marginTop: "10px",
            }}
            className="ant-btn-outline"
          >
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default CallSupervisorFeedback;
