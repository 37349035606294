import React, { useState, useEffect } from "react";
import { Emoji } from "../../../../util/Emoji";

const RatePerformance = (props) => {
  const [clickFlag, setClick] = useState([false, false, false, false, false]);
  const [postitionButtonSet, setPostitionButtonSet] = useState(-1);

  useEffect(() => {
    if (props.answer && props.answer.length > 0) {
      let answer = props.answer.filter(
        (answer) => answer.question_answer.answer_type === "rate"
      )[0].question_answer.answer;
      let array = [false, false, false, false, false];
      if (answer === "Bad") {
        setPostitionButtonSet(0);
        array[0] = true;
      } else if (answer === "Unsatisfied") {
        setPostitionButtonSet(1);
        array[1] = true;
      } else if (answer === "Okay") {
        setPostitionButtonSet(2);
        array[2] = true;
      } else if (answer === "Satisfied") {
        setPostitionButtonSet(3);
        array[3] = true;
      } else if (answer === "Good") {
        setPostitionButtonSet(4);
        array[4] = true;
      }
      setClick(array);
    }
    // eslint-disable-next-line
  }, []);

  const setCilckFlag = (pos) => {
    let flagArray;
    if (postitionButtonSet === -1) {
      setPostitionButtonSet(pos);
      flagArray = [false, false, false, false, false];
      flagArray[pos] = !flagArray[pos];
    } else {
      flagArray = [...clickFlag];
      if (pos !== postitionButtonSet) {
        flagArray[pos] = !flagArray[pos];
        flagArray[postitionButtonSet] = !flagArray[postitionButtonSet];
        setPostitionButtonSet(pos);
      } else {
        flagArray[pos] = !flagArray[pos];
        setPostitionButtonSet(-1);
      }
    }
    setClick(flagArray);
  };

  return (
    <div>
      <div className={"some_test my-1"}>
        <label className="largeFont">
          {props.fieldObj.is_required && (
            <span style={{ color: "red", fontSize: 15 }}>*</span>
          )}
          {props.fieldObj.title}
        </label>
      </div>

      <div className={"row"}>
        <div className={"col Bad"}>
          <Emoji
            id={0}
            emoji="🙁"
            emojiTitle="Bad"
            setClick={setCilckFlag}
            clickFlag={clickFlag}
            fieldObj={props.fieldObj}
            handleFieldChange={() =>
              props.handleFieldChange(
                postitionButtonSet === 0 ? null : "Bad",
                props.fieldObj
              )
            }
          />
        </div>
        <div className={"col Unsatisfied"}>
          <Emoji
            id={1}
            emoji="😕"
            emojiTitle="Unsatisfied"
            setClick={setCilckFlag}
            clickFlag={clickFlag}
            fieldObj={props.fieldObj}
            handleFieldChange={() =>
              props.handleFieldChange(
                postitionButtonSet === 1 ? null : "Unsatisfied",
                props.fieldObj
              )
            }
          />
        </div>
        <div className={"col Okay"}>
          <Emoji
            id={2}
            emoji="🙂"
            emojiTitle="Okay"
            setClick={setCilckFlag}
            clickFlag={clickFlag}
            fieldObj={props.fieldObj}
            handleFieldChange={() =>
              props.handleFieldChange(
                postitionButtonSet === 2 ? null : "Okay",
                props.fieldObj
              )
            }
          />
        </div>
        <div className={"col Satisfied"}>
          <Emoji
            id={3}
            emoji="😀"
            emojiTitle="Satisfied"
            setClick={setCilckFlag}
            clickFlag={clickFlag}
            fieldObj={props.fieldObj}
            handleFieldChange={() =>
              props.handleFieldChange(
                postitionButtonSet === 3 ? null : "Satisfied",
                props.fieldObj
              )
            }
          />
        </div>
        <div className={"col Good"}>
          <Emoji
            id={4}
            emoji="🤩"
            emojiTitle="Good"
            setClick={setCilckFlag}
            clickFlag={clickFlag}
            fieldObj={props.fieldObj}
            handleFieldChange={() =>
              props.handleFieldChange(
                postitionButtonSet === 4 ? null : "Good",
                props.fieldObj
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RatePerformance;
