import React from "react";
import { Provider } from "react-redux";
//import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, HashRouter } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import "./firebase/firebase";
import App from "./containers/App/index";
import { CLOUD_FRONT_URL, ORIGIN_URL, REDIRECT_URL } from "./util/Api";
import { dumbData } from "./util/trackingAPI";

const { store } = configureStore(/* provide initial state if any */);

const NextApp = () => {
  const activityList = localStorage.getItem("activityList");
  if (activityList && activityList.length > 1) {
    dumbData(JSON.parse(localStorage.getItem("activityList")));
  }
  if (
    window.location.href.includes(CLOUD_FRONT_URL) ||
    window.location.href.includes(ORIGIN_URL)
  ) {
    window.location.href = window.location.href.replace(
      window.location.href.includes(CLOUD_FRONT_URL)
        ? CLOUD_FRONT_URL
        : ORIGIN_URL,
      REDIRECT_URL
    );
  }

  localStorage.setItem("os", navigator.platform);

  return (
    <Provider store={store}>
      <HashRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </HashRouter>
    </Provider>
  );
};

export default NextApp;
