export function phoneNumberParser(phone) {
  if (phone) {
    let res = phone.replace(/\D/g, "");
    if (res.length > 10) {
      return res;
    } else {
      let newStr = "1" + res;
      return newStr;
    }
  }
  return "";
}
export function phoneNumberFormatter(phone) {
  if (phone) {
    let res = phone.replace(/\D/g, "");
    if (res.length > 10) {
      if (res.length === 11) {
        return (
          "+" +
          res.substring(0, 1) +
          " (" +
          res.substring(1, 4) +
          ") " +
          res.substring(4, 7) +
          "-" +
          res.substring(7, res.length)
        );
      } else if (res.length === 12) {
        return (
          "+" +
          res.substring(0, 2) +
          " " +
          res.substring(2, 7) +
          "-" +
          res.substring(7, res.length)
        );
      } else {
        return res;
      }
    } else {
      let newStr =
        "+1 (" +
        res.substring(0, 3) +
        ") " +
        res.substring(3, 6) +
        "-" +
        res.substring(6, res.length);
      return newStr;
    }
  }
  return phone;
}
