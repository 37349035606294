import {Text} from "slate";
import {jsx} from "slate-hyperscript";
import { TextFormatter } from "../../util/TextFormatter";
export const serialize = node => {
    if (Text.isText(node)) {
        let text = node.text;
        if (node.bold) {
            text = `<strong>${text}</strong>`
        }
        if (node.underline) {
            text = `<u>${text}</u>`
        }
        if (node.code) {
            text = `<code>${text}</code>`
        }
        if (node.italic) {
            text = `<em>${text}</em>`
        }
        return TextFormatter(text)
    }
    let children = node.children.map(n => serialize(n)).join('')
    children = TextFormatter(children)
    switch (node.type) {
        case 'text':
            return `<br/>`
        case 'block-quote':
            return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
            if(!children){
                return null;
            }
            return `<p>${children}</p>`
        case 'heading-one':
            return `<h1>${children}</h1>`
        case 'heading-two':
            return `<h2>${children}</h2>`
        case 'numbered-list':
            return `<ol>${children}</ol>`
        case 'bulleted-list':
            return `<ul>${children}</ul>`
        case 'list-item':
            return `<li>${children}</li>`
        case 'mention':
            return `<span style="color: darkblue">${node.character}</span>`
        default:
            return children
    }
}
export const deserialize = el => {
    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    }
    const children = Array.from(el.childNodes).map(deserialize)
    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children)
        case 'BLOCKQUOTE':
            return jsx('element', {type: 'quote'}, children)
        case 'P':
            if (children.length > 0) {
                return jsx('element', {type: 'paragraph'}, children)
            } else {
                return jsx('element',{type:'text'}, [{text:'\n'}])
            }
        case 'STRONG':
            return jsx('text', {bold: true}, children)
        case 'EM':
            return jsx('text', {italic: true}, children)
        case 'U':
            return jsx('text', {underline: true}, children)
        case 'CODE':
            return jsx('text', {code: true}, children)
        case 'SPAN':
            return jsx('element', {type: 'mention', character: children.toString()}, [{text: ""}])
        case 'H1':
            return jsx('element', {type: 'heading-one'}, children)
        case 'H2':
            return jsx('element', {type: 'heading-two'}, children)
        case 'LI':
            return jsx('element', {type: 'list-item'}, children)
        case 'OL':
            return jsx('element', {type: 'numbered-list'}, children)
        case 'UL':
            return jsx('element', {type: 'bulleted-list'}, children)
        default:
            return el.textContent
    }
}
