import React, { Component } from "react";
import APIcomponent from "./Marketing/Test/FormComponents/APIcomponent";
import AttachmentComponent from "./Marketing/Test/FormComponents/AttachmentComponent";
import BooleanComponent from "./Marketing/Test/FormComponents/BooleanComponent";
import GenericFormComponent from "./Marketing/Test/FormComponents/GenericFormComponent";
import NoRemarkComponent from "./Marketing/Test/FormComponents/NoRemarkComponent";
import RatePerformance from "./Marketing/Test/FormComponents/RatePerformance";
import SliderComponent from "./Marketing/Test/FormComponents/sliderComponent";
import YesAttachmentComponent from "./Marketing/Test/FormComponents/YesAttachmentComponent";
import YesQuestion from "./Marketing/Test/FormComponents/YesQuestion";
import YesRemarkComponent from "./Marketing/Test/FormComponents/YesRemarkComponent";

let newTestFields = [];
let singleRender = false;

class RenderQuestionFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testId: this.props.testObj?.id,
      attachments: [],
      attachmentsId: [],
      testFieldAnswers: [],
      testFields: [],
      approachFile: null,
      formType: null,
      remark: null,
    };
  }
  componentDidMount() {
    newTestFields = this.props.testFields;
    if (this.props.testFieldsAnswer && this.props.testFieldsAnswer.length > 0) {
      let answer = [];
      this.props.testFieldsAnswer.map((ele) => {
        if (ele.question_answer) {
          let obj = {
            answer: ele.question_answer.answer,
            apiQuestion: false,
            comment: ele.question_answer.remark,
            que_value: ele.question_answer.ques_title,
            question_id: ele.question_answer.ques_id,
          };
          answer.push(obj);
        }
        return null;
      });
      this.setState({ testFieldAnswers: answer });
    }
  }

  customRequest = (file) => {
    console.log(file);
  };

  makeAnswersUpdate = (answer, question) => {
    let selected = answer.filter((ele) => ele.question_id === question.id);
    let dependentId = [];
    question.dependent.map((ele) => {
      dependentId.push(ele.id);
      return null;
    });
    if (selected && selected[0]) {
      selected[0]["dependent"] = dependentId;
    }
    return answer;
  };

  handleFieldChange = (
    value,
    fieldObj,
    attachmentName = null,
    comment = false,
    parentId = null,
    apiQuestion = false,
    erseApianswer = false
  ) => {
    let answers = [...this.state.testFieldAnswers];
    let checkIndex = answers.findIndex(
      (ans) => ans.question_id === fieldObj.id
    );
    let obj;
    let flag = false;
    let fileChnageFlag = false;
    let newTestAnswerArray = [];
    let newAnswer = [];
    let isParent = false;
    // checking if this field obj already exist in answer array
    if (checkIndex !== -1) {
      if (fieldObj.answer_type === "attachment") {
        if (value) {
          let allAttachments = [...this.state.attachments];
          let attachmentsId = [...this.state.attachmentsId];
          let ele = attachmentsId.find(
            (obj) => obj.id === fieldObj.id && obj.parentId === parentId
          );
          if (!ele) {
            attachmentsId.push({
              id: fieldObj.id,
              parentId: parentId,
              count: 1,
            });
          } else {
            ele.count += 1;
          }
          let particulerAttachment = allAttachments.find(
            (attach) => attach.id === fieldObj.id && attach.parent === parentId
          );
          particulerAttachment.files.push(value);
          particulerAttachment.parent = parentId;
          this.setState(
            {
              attachments: allAttachments,
              attachmentsId: attachmentsId,
            },
            () => this.props.setAttachmentId(attachmentsId),
            this.props.setAttachments(allAttachments)
          );
        } else {
          let allAttachments = [...this.state.attachments];
          let attachmentsId = [...this.state.attachmentsId];
          let ele = attachmentsId.find(
            (obj) => obj.id === fieldObj.id && obj.parentId === parentId
          );

          ele.count -= 1;
          // count here 0 then remove attachmentsId list and chnage answer array

          let answeElements = [...this.state.testFieldAnswers];
          let needTochange = false;
          // let newTestAnswerArray = [];
          if (ele.count <= 0) {
            needTochange = true;
            fileChnageFlag = true;
            ele.count = 0;
            answeElements.map((answer) => {
              if (!(answer.question_id === ele.id)) {
                if (ele.parentId) {
                  if (answer.parent_question_id === ele.parentId) {
                    newTestAnswerArray.push(answer);
                  }
                } else {
                  newTestAnswerArray.push(answer);
                }
              }
              return null;
            });
          }

          let filterAttachments = allAttachments.find(
            (attach) => attach.id === fieldObj.id && attach.parent === parentId
          );
          let filesArray = [];

          filterAttachments.files.map((ele) => {
            if (ele.name !== attachmentName) {
              filesArray.push(ele);
            }
            return null;
          });
          filterAttachments.files = filesArray;
          if (filesArray.length <= 0) {
            allAttachments.splice(0, 1);
            flag = true;
          }
          if (!needTochange) {
            newTestAnswerArray = answeElements;
          }
          this.setState(
            {
              attachments: allAttachments,
              attachmentsId: attachmentsId,
            },
            () => this.props.setAttachmentId(attachmentsId),
            this.props.setAttachments(allAttachments)
          );
        }
      } else {
        let isAnArray = false;
        if (Array.isArray(value)) {
          isAnArray = true;
        }
        answers[checkIndex].apiQuestion = apiQuestion;
        if (!parentId) {
          if (comment) {
            answers[checkIndex].comment = value;
          } else {
            answers[checkIndex].answer = isAnArray
              ? this.convertArrayToString(value)
              : value;
            if (
              (isAnArray && value.length <= 0) ||
              (!isAnArray && !value && value === "")
            ) {
              answers.splice(checkIndex, 1);
            } else {
              if (value && !comment) {
                answers[checkIndex].comment = null;
              }
            }
          }
        } else {
          let parentAnswer = answers.filter(
            (q) =>
              q.question_id === fieldObj.id && q.parent_question_id === parentId
          );
          if (parentAnswer && parentAnswer.length === 1) {
            if (comment) {
              parentAnswer[0].comment = isAnArray
                ? this.convertArrayToString(value)
                : value;
            } else {
              parentAnswer[0].answer = isAnArray
                ? this.convertArrayToString(value)
                : value;
              if (value && !comment) {
                parentAnswer[0].comment = null;
              }
            }
          } else {
            if (answers[checkIndex].parent_question_id === parentId) {
              if (comment) {
                answers[checkIndex].comment = isAnArray
                  ? this.convertArrayToString(value)
                  : value;
              } else {
                answers[checkIndex].answer = isAnArray
                  ? this.convertArrayToString(value)
                  : value;
                if (value && !comment) {
                  answers[checkIndex].comment = null;
                }
              }
            } else {
              isParent = true;
              obj = {
                question_id: fieldObj.id,
                que_value: fieldObj.title,
                answer: isAnArray ? this.convertArrayToString(value) : value,
                parent_question_id: parentId,
                is_required: fieldObj.is_required,
              };
            }
          }
        }
      }
    } else {
      let isAnArray = false;
      if (Array.isArray(value)) {
        isAnArray = true;
      }
      // if no create a new obj
      if (fieldObj.answer_type !== "attachment") {
        if (parentId) {
          obj = {
            question_id: fieldObj.id,
            que_value: fieldObj.title,
            answer: isAnArray ? this.convertArrayToString(value) : value,
            parent_question_id: parentId,
            is_required: fieldObj.is_required,
            apiQuestion: apiQuestion,
          };
        } else {
          obj = {
            question_id: fieldObj.id,
            que_value: fieldObj.title,
            answer: isAnArray ? this.convertArrayToString(value) : value,
            is_required: fieldObj.is_required,
            apiQuestion: apiQuestion,
          };
        }
      } else {
        if (value) {
          obj = {
            question_id: fieldObj.id,
            answer: "submitted",
            is_required: fieldObj.is_required,
            parent_question_id: parentId ? parentId : null,
            apiQuestion: apiQuestion,
          };
          let attachments = [...this.state.attachments];
          let attachmentsId = [...this.state.attachmentsId];

          let ele = attachmentsId.find(
            (obj) => obj.id === fieldObj.id && obj.parentId === parentId
          );
          if (ele) {
            ele.count = 1;
          } else {
            attachmentsId.push({
              id: fieldObj.id,
              parentId: parentId,
              count: 1,
            });
          }
          let attachObj = {
            id: fieldObj.id,
            files: [value],
            parent: parentId,
          };
          attachments.push(attachObj);
          this.setState(
            {
              attachments: attachments,
              attachmentsId: attachmentsId,
            },
            () => this.props.setAttachmentId(attachmentsId),
            this.props.setAttachments(attachments)
          );
        }
      }
    }

    if (checkIndex === -1 || isParent) {
      // if answer field does not exist then push to answer array
      answers.push(obj);
    }

    //need to set the inputfields change

    let questionFields = [];

    // if (isYesQuestionInvolve) {
    questionFields = [...newTestFields];
    // } else {
    //   questionFields = [...this.state.testFields];
    // }

    let checkIn = questionFields.findIndex((ans) => ans.id === fieldObj.id);
    checkIn = questionFields.findIndex((ans) => ans.id === fieldObj.id);
    if (fieldObj.answer_type === "attachment") {
      if (value) {
        if (questionFields[checkIn].values) {
          questionFields[checkIn].values.push(value);
        } else {
          questionFields[checkIn].values = [];
          questionFields[checkIn].values.push(value);
        }
      } else {
        let allAttachments = [...questionFields[checkIn].values];
        let newAttachmentsList = [];
        for (
          let attachment = 0;
          attachment < allAttachments.length;
          attachment++
        ) {
          if (attachmentName !== allAttachments[attachment].name) {
            newAttachmentsList.push(allAttachments[attachment]);
          }
        }
        questionFields[checkIn].values = newAttachmentsList;
      }
    } else {
      if (parentId) {
        let parentQuestion = questionFields.find(
          (ans) => ans.id === fieldObj.id && ans.pId === parentId
        );

        if (parentQuestion && parentQuestion.length === 1) {
          if (comment) {
            parentQuestion.comment = value;
          } else {
            parentQuestion.values = value;
            if (value && !comment) {
              parentQuestion.comment = null;
            }
          }
          // questionFields.push(parentQuestion)
        } else {
          if (comment) {
            questionFields[checkIn].comment = value;
          } else {
            questionFields[checkIn].values = value;
            if (value && !comment) {
              questionFields[checkIn].comment = null;
            }
          }
        }
      } else {
        if (comment) {
          questionFields[checkIn].comment = value;
        } else {
          questionFields[checkIn].values = value;
          if (value && !comment) {
            questionFields[checkIn].comment = null;
          }
        }
      }
    }

    let filterAnswerList = [];

    if (erseApianswer) {
      answers.map((answer) => {
        if (answer.apiQuestion === false) {
          filterAnswerList.push(answer);
        }
        return null;
      });
    }

    let finalAnswerList = fileChnageFlag
      ? newTestAnswerArray
      : flag
      ? newAnswer
      : answers;
    // Need to do chnages here
    if (fieldObj.dependent && fieldObj.dependent.length > 0) {
      finalAnswerList = this.makeAnswersUpdate(finalAnswerList, fieldObj);
    }

    this.setState(
      {
        testFieldAnswers: finalAnswerList,
        testFields: questionFields,
      },
      () => this.props.setTestFieldsAnswer(this.state.testFieldAnswers)
    );
    if (erseApianswer) {
      this.setState(
        {
          testFieldAnswers: filterAnswerList,
        },
        () => this.props.setTestFieldsAnswer(this.state.testFieldAnswers)
      );
    }
  };

  handleFileChange = (file, obj, parentId) => {
    this.setState({
      approachFile: {
        file: file,
        parentId: parentId,
      },
    });
    let attachmentsId = [...this.state.attachmentsId];
    attachmentsId.push({
      id: obj.id,
      parentId: parentId,
    });
    this.setState(
      {
        attachmentsId: attachmentsId,
      },
      () => this.props.setAttachmentId(attachmentsId)
    );
  };

  handleRemoveFile = (obj, parentId) => {
    this.setState({
      approachFile: null,
    });
    let allAttachID = this.state.attachmentsId.find((ele) => ele.id !== obj.id);

    this.setState(
      {
        attachmentsId: allAttachID ? allAttachID : [],
      },
      () => this.props.setAttachmentId(allAttachID ? allAttachID : [])
    );
  };

  setEnabled = (val) => {
    this.setState({ disableSubmit: val });
  };

  setTestFields = (newQuestion = null, questionList = null) => {
    if (newQuestion) {
      if (!singleRender) {
        newTestFields = [...newTestFields, newQuestion];
      } else {
        if (newQuestion.length) {
          newTestFields = [...newQuestion];
        } else {
          newTestFields = [...newTestFields, newQuestion];
        }
      }
      singleRender = true;
    }
  };

  convertArrayToString = (val) => {
    let newData = "";
    if (val.length > 0) {
      newData = val[0];
      val.forEach((item, index) => {
        if (index > 0) {
          newData += ", " + item;
        }
      });
    }
    return newData;
  };

  renderFormComponent = (
    fieldObj,
    parentId = null,
    apiQuestion = false,
    answer = null
  ) => {
    if (fieldObj.noRender) return null;

    if (fieldObj.category === "parent_child") {
      return (
        <APIcomponent
          fieldObj={fieldObj}
          handleFieldChange={this.handleFieldChange}
          customRequest={this.customRequest}
          formType={this.state.formType}
          renderFormComponent={this.renderFormComponent}
          parentId={parentId}
          testFields={this.state.testFields}
          setTestFields={this.setTestFields}
          apiQuestion={apiQuestion}
          setEnabled={this.setEnabled}
          answer={answer}
          edit={this.props.edit}
        />
      );
    }

    switch (fieldObj.answer_type) {
      case "headline":
        return (
          <div className="my-2 pt-2 border-top">
            <label className="font-500">{fieldObj.title}</label>
          </div>
        );

      case "yes_question":
        // isYesQuestionInvolve = true;
        return (
          <YesQuestion
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            renderFormComponent={this.renderFormComponent}
            parentId={parentId}
            testFields={this.state.testFields}
            setTestFields={this.setTestFields}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      case "note":
        return (
          <div className="my-2 pt-3 border-top">
            <div className="alert alert-warning" role="alert">
              {fieldObj.title}
            </div>
          </div>
        );

      case "rate":
        return (
          <RatePerformance
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      case "yes_remark":
        return (
          <YesRemarkComponent
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      case "no_remark":
        return (
          <NoRemarkComponent
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );
      case "yes_attachment":
        return (
          <YesAttachmentComponent
            fieldObj={fieldObj}
            handleFileChange={this.handleFileChange}
            handleFieldChange={this.handleFieldChange}
            customRequest={this.customRequest}
            approachFile={this.state.approachFile}
            handleRemoveFile={this.handleRemoveFile}
            parentId={parentId}
            testFields={this.state.testFields}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );
      case "boolean":
        return (
          <BooleanComponent
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      case "option":
      case "text":
      case "long_text":
      case "integer":
      case "multi_select":
        return (
          <GenericFormComponent
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      case "attachment":
        return (
          <AttachmentComponent
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            customRequest={this.customRequest}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      case "slider":
        return (
          <SliderComponent
            fieldObj={fieldObj}
            handleFieldChange={this.handleFieldChange}
            parentId={parentId}
            apiQuestion={apiQuestion}
            answer={answer}
            edit={this.props.edit}
          />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        {this.props.testFields &&
          this.props.testFields.map((field, index) => (
            <div key={index}>
              <div>
                {this.renderFormComponent(
                  field,
                  null,
                  false,
                  this.props?.testFieldsAnswer
                )}
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default RenderQuestionFields;
