import React from "react";
import { Button, Radio, Tooltip, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const YesAttachmentComponent = (props) => {
  return (
    <div className="radio-margin">
      <label className="largeFont">
        {props.fieldObj.is_required && (
          <span style={{ color: "red", fontSize: 15 }}>*</span>
        )}
        {props.fieldObj.title} :
      </label>
      <div className="radio" style={{ marginBottom: "5px" }}>
        <Radio.Group
          value={props.fieldObj.values}
          onChange={(e) =>
            props.handleFieldChange(
              e.target.value,
              props.fieldObj,
              null,
              null,
              props.parentId,
              props.parent
            )
          }
        >
          <Radio value={"Yes"}>Yes</Radio>
          <Radio value={"No"}>No</Radio>
        </Radio.Group>
      </div>
      <>
        {props.fieldObj.values !== undefined &&
          props.fieldObj.values !== null &&
          props.fieldObj.values === "No" && (
            <div className="mt-3">
              <label className="largeFont">Comment :</label>
              <TextArea
                placeholder="Added Comment Here"
                value={props.fieldObj.comment}
                onChange={(e) =>
                  props.handleFieldChange(
                    e.target.value,
                    props.fieldObj,
                    null,
                    true,
                    props.parentId,
                    props.apiQuestion
                  )
                }
              />
            </div>
          )}
      </>

      <>
        {props.fieldObj.title === "Approach.txt added?" &&
          props.fieldObj.values !== undefined &&
          props.fieldObj.values !== null &&
          props.fieldObj.values === "Yes" && (
            <>
              <div style={{ marginTop: "20px" }} className={"row mx-0"}>
                <div className={""}>
                  <label className="largeFont">Upload Approch.txt file :</label>
                  <div className={"col-4 px-0"}>
                    <Upload
                      accept="application/pdf,.docx,image/jpeg,image/png,.zip,.rar,.7zip,.txt"
                      onChange={(e) =>
                        props.handleFileChange(
                          e.file,
                          props.fieldObj,
                          props.parentId
                        )
                      }
                      multiple={false}
                      showUploadList={false}
                      customRequest={(f) => props.customRequest(f)}
                    >
                      <Tooltip title="Uploaded File">
                        <div>
                          <Button
                            className="uploadbtn"
                            style={{
                              background: "transparent",
                              color: "#6B6E70",
                            }}
                            icon={<UploadOutlined />}
                          >
                            Uploaded File
                          </Button>
                        </div>
                      </Tooltip>
                    </Upload>
                  </div>
                </div>
              </div>
              {props.approachFile && (
                <div className="col-12 mt-0 p-0" style={{ margin: "10px 0px" }}>
                  <label style={{ fontWeight: "bold" }}>Selected File</label>
                  <div className="text-file-attached ">
                    <div className="mb-2 ml-0 attachmentSty">
                      {props.approachFile.file.name}
                      <CloseCircleOutlined
                        className="deleteicon"
                        style={{
                          color: "red",
                          fontSize: 14,
                          marginLeft: "10px",
                          borderWidth: "0px",
                          borderColor: "white",
                          float: "revert",
                          marginTop: "0px",
                        }}
                        onClick={() =>
                          props.handleRemoveFile(props.fieldObj, props.parentId)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
      </>
    </div>
  );
};

export default YesAttachmentComponent;
