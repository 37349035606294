import {
  Button,
  DatePicker,
  Input,
  Select,
  Form,
  Checkbox,
  Divider,
  Popover,
  //Alert,
} from "antd";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { messageError, messageSuccess } from "../../util/AlertMessage";
import axiosJSON from "../../util/Api";
import moment from "moment";
import { putData } from "../../util/trackingAPI";

const AddCertificate = ({ certificate, closeModal }) => {
  const initialValues = certificate
    ? {
        name: certificate.certificate.name,
        organization: certificate.certificate.organization,
        issued_date: moment(certificate.issued_date),
        expiry_date: certificate.has_expiry
          ? moment(certificate.expiry_date)
          : null,
        credential_id: certificate.credential_id,
        credential_url: certificate.credential_url,
      }
    : {};
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [allCertifications, setAllcertifications] = useState([]);
  const [isExpiry, setIsExpiry] = useState(
    certificate ? certificate.has_expiry : false
  );
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const getAllOrganizations = (query) => {
      axiosJSON
        .get(
          `api/employee_certificate/get_all/?organization=true&query=${query}`
        )
        .then((data) => {
          setAllOrganizations(data.data.data);
        })
        .catch((error) => {
          messageError(error);
        });
    };
    getAllOrganizations("");
  }, []);

  const addItem = (e) => {
    e.preventDefault();
    if (name) {
      if (
        allOrganizations.filter(
          (item) => item.toLowerCase() === name.toLowerCase()
        ).length > 0
      ) {
        messageError("Name already Present");
      } else {
        setAllOrganizations([...allOrganizations, name]);
        setName("");
      }
    } else messageError("Please enter Name");
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const addCertificate = (e) => {
    putData("Add button clicked to add certificate", "log1");
    e.preventDefault();
    let obj = { name: name };
    setAllcertifications([...allCertifications, obj]);
    setName("");
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const getCertications = () => {
    axiosJSON
      .get(
        `api/employee_certificate/get_all/?organization_name=${form.getFieldValue(
          "organization"
        )}`
      )
      .then((data) => {
        setAllcertifications(data.data.data);
      })
      .catch((error) => {
        messageError(error);
      });
  };

  const onSubmit = (values) => {
    putData(`submit clicked`, "log1");
    let body = {
      // certificate_id: values.name.id,
      certificate_name: values.name,
      organization: values.organization,
      has_expiry: isExpiry,
      issued_date: moment(values.issued_date).format("YYYY-MM-DD"),
      expiry_date: isExpiry
        ? moment(values.expiry_date).format("YYYY-MM-DD")
        : null,
      credential_id: values.credential_id,
      credential_url: values.credential_url,
    };

    let apiCall = certificate
      ? axiosJSON.put(`/api/employee_certificate/${certificate.id}/`, body)
      : axiosJSON.post("/api/employee_certificate/", body);

    apiCall
      .then(({ data, status }) => {
        messageSuccess(data.message);
        closeModal();
      })
      .catch((e) => {
        messageError(e);
      });
  };

  const onValuesChange = (changedValues) => {
    putData(`values changed`, "log1");
    if (changedValues && changedValues.hasOwnProperty("organization")) {
      form.setFieldsValue({ name: "" });
      getCertications();
    } else if (
      changedValues &&
      isExpiry &&
      changedValues.hasOwnProperty("issued_date")
    ) {
      form.setFieldsValue({ expiry_date: null });
    }
  };

  const disableEndDate = (current) => {
    if (form.getFieldValue("issued_date")) {
      return (
        current &&
        current < moment(form.getFieldValue("issued_date")).endOf("day")
      );
    }
  };

  const onIsExpiryChange = (e) => {
    putData(`expiry change selected = { ${e.target.checked} }`, "log1");
    setIsExpiry(e.target.checked);
    form.setFieldsValue({ expiry_date: null });
  };

  const getContent = (val) => {
    return `${val} will be added to the List Temporarily unless you select the previously added options and submit the Form`;
  };

  useEffect(() => {
    form.validateFields(["expiry_date"]);
  }, [isExpiry, form]);

  return (
    <div className="whitecontainer" style={{ height: "auto" }}>
      {/* <Alert
        message="Please Select Organization Before Selecting Certificate"
        type="warning"
        showIcon
        className="warning-feedback"
      /> */}
      <Form
        name="basic"
        autoComplete="off"
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
      >
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="profileformnew2">
              <Form.Item
                name="organization"
                label={
                  <>
                    Issued Organization Name
                    <Popover content={getContent("Organizations")}>
                      <InfoCircleOutlined className="ml-1" />
                    </Popover>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter Issued Organization Name!",
                  },
                ]}
              >
                <Select
                  className="form-control"
                  placeholder="Organization Name"
                  disabled={certificate}
                  showSearch
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider className="my-2" />
                      <div className="pb-3 px-2 w-100">
                        <Input
                          className="mr-2"
                          style={{ width: "80%" }}
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Button
                          className="mb-0"
                          type="default"
                          icon={<PlusOutlined />}
                          onClick={addItem}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  options={allOrganizations.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label={
                  <>
                    Certification Name
                    <Popover content={getContent("Certificates")}>
                      <InfoCircleOutlined className="ml-1" />
                    </Popover>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select Certification Name!",
                  },
                ]}
              >
                <Select
                  className="form-control"
                  placeholder="Certificate Name"
                  showSearch
                  // onFocus={getCertications}
                  disabled={certificate}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider className="my-2" />
                      <div className="pb-3 px-2 w-100">
                        <Input
                          className="mr-2"
                          style={{ width: "80%" }}
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Button
                          className="mb-0"
                          type="default"
                          icon={<PlusOutlined />}
                          onClick={addCertificate}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  options={allCertifications.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="issued_date"
                label="Issued Date"
                rules={[
                  {
                    required: true,
                    message: "Please enter Issued Date!",
                  },
                ]}
              >
                <DatePicker format="MM-DD-YYYY" placeholder="Issued Date" />
              </Form.Item>
              <div>
                <Checkbox checked={isExpiry} onChange={onIsExpiryChange}>
                  This Certification has Expiry Date
                </Checkbox>
              </div>
              <Form.Item
                name="expiry_date"
                label="Expiry Date"
                rules={[
                  {
                    required: isExpiry,
                    message: "Please enter Expiry Date!",
                  },
                ]}
              >
                <DatePicker
                  format="MM-DD-YYYY"
                  placeholder="Expiry Date"
                  disabled={!isExpiry}
                  disabledDate={disableEndDate}
                />
              </Form.Item>
              <Form.Item name="credential_id" label="Credential Id">
                <Input />
              </Form.Item>
              <Form.Item name="credential_url" label="Credential Url">
                <Input />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <Form.Item>
            <Button type="default" htmlType="submit" className="submit-button">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddCertificate;
