import React, {useState} from 'react'

export const Emoji = (props) => {
    const [cssClass, setCssClass] = useState("normal");
    const [click,setClick] = useState(false);

    return (
        <div className="state_set"style={{cursor:"pointer"}}>
            <h1 style={{display:"flex",justifyContent:"center"}}>{props.emoji}</h1>
            <p
            style={{display:"flex",justifyContent:"center",border:`1px solid`,padding:"5px", borderRadius:"20px"}}
            onMouseEnter={()=>{
                setCssClass("onHover")
            }}

            onMouseLeave={()=>{
                setCssClass("normal")
            }}

            onClick={(e)=>{
                setCssClass("onClicked")
                setClick(!click)
                props.setClick(props.id)
                props.handleFieldChange(!click ? props.emojiTitle:null, props.fieldObj, null, null, props.parentId )
            }}
            className={props.clickFlag[props.id]?"onClicked":cssClass}
            >{props.emojiTitle}</p>
        </div>
    )
}
