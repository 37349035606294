import React, { Component } from "react";
import { Rate, Input, Spin, Select, Button } from "antd";

import SlateEditor from "../../../components/CommentTagEditor";
import { serialize } from "../../../components/Editor/parseJson";
import axiosJSON from "../../../util/Api";
import { messageError, messageSuccess } from "../../../util/AlertMessage";
import { putData } from "../../../util/trackingAPI";
// import getDeserializeText from "../../../util/DeserializingText";

const beforeMatch = /^@(\w+)$/;
const beforeStr = "@";
const afterStr = "";

const FEEDBACK_TYPE = [
  {
    id: 1,
    value: "engineering_issue",
    name: "Engineering Issue",
  },
  {
    id: 2,
    value: "2_week",
    name: "2 Week of Joining",
  },
  {
    id: 3,
    value: "independent",
    name: "Independent",
  },
  {
    id: 4,
    value: "monthly",
    name: "Monthly",
  },
];

export default class AddFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      feedbackType: null,
      content: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      selected_user: [],
      loader: false,
      user_list: [],
    };
  }

  componentDidMount() {
    this.getUserList("");
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  handelSubmit = () => {
    putData(`submit clicked`, "engineering_module");
    //TODO
    if (
      !this.state.content ||
      serialize(this.state.content) === null ||
      serialize(this.state.content) === "<p></p>" ||
      !this.state.feedbackType
    ) {
      return messageError("Please fill all mandatory fields");
    }
    let body = {
      description: serialize(this.state.content),
      rating: this.state.rating,
      feedback_type: this.state.feedbackType,
      project: this.props.project_id,
    };
    this.setState({
      loader: true,
    });
    axiosJSON
      .post(
        `api/consultant/${this.props.project.consultant.id}/feedback/`,
        body
      )
      .then(({ status, data }) => {
        messageSuccess("Feedback Added");
        this.setState({
          loader: false,
        });
        this.props.closeModal();
      })
      .catch((err) => {
        console.log("Here");
        this.setState({
          loader: false,
        });
        this.props.closeModal();
        messageError(err);
      });
  };

  getUserList(param) {
    axiosJSON
      .get("api/employee/?query=" + param)
      .then(({ status, data }) => {
        if (param === "") {
          this.setState({ user_list: data.data });
        } else {
          this.setState({ user_list: data.data });
        }
      })
      .catch((error) => {
        messageError(error);
      });
  }

  onFeedbackChange = (value) => {
    putData(`feedback value = { ${value} }`, "engineering_module");
    this.setState({
      feedbackType: value ? value : null,
    });
  };

  changeRating = (newRating, name) => {
    putData(`rating = { ${newRating} }`, "engineering_module");
    this.setState({
      rating: newRating,
    });
  };

  handleChange = (e) => {
    this.setState({
      content: e,
    });
  };

  onSelectUser = (value) => {
    putData(`user selected = { ${value} }`, "engineering_module");
    let user = this.state.selected_user;
    user.push(value);
    this.setState({
      selected_user: user,
    });
  };

  render() {
    return (
      <div>
        <Spin spinning={this.state.loader}>
          <div>
            <div>
              <div className="row mt-3">
                <div className="col-md-2">
                  <label className="pt-2 pl-1 font-weight-500">
                    Consultant
                  </label>
                </div>
                <div className="col-md-8">
                  <Input
                    disabled={true}
                    value={this.props.project.consultant.name}
                  />
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row mt-3">
                <div className="col-md-2">
                  <label className="pt-2 pl-1 font-weight-500">
                    Department
                  </label>
                </div>
                <div style={{ marginTop: "8px" }} className="col-md-8">
                  <Input disabled={true} value={"Engineering"} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-2">
                  <label className="pt-2 pl-1 font-weight-500">Project</label>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className={"col-md-4"}>
                      <Input
                        disabled={true}
                        value={this.props.project.submission.client}
                      />
                    </div>
                    <div className={"col-md-4"}>
                      <Input
                        disabled={true}
                        value={this.props.project.submission.vendor}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-2">
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                  <label className="pt-2 pl-1 font-weight-500">
                    Feedback Type
                  </label>
                </div>
                <div className="col-md-8">
                  <Select
                    allowClear
                    value={this.state.feedbackType}
                    placeholder="Feedback Types"
                    onChange={this.onFeedbackChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {FEEDBACK_TYPE.map((item, i) => (
                      <Select.Option key={i} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-2">
                  <label className="pt-2 pl-1 font-weight-500"> Rating </label>
                </div>
                <div className="col-md-4">
                  <span
                    style={{
                      backgroundColor: "dodgerblue",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <Rate
                      allowHalf={false}
                      value={this.state.rating}
                      onChange={this.changeRating}
                    />
                  </span>
                </div>
              </div>
              <div style={{ marginLeft: "1px" }} className="row mt-3">
                <span style={{ color: "red", fontSize: 9, marginTop: "15px" }}>
                  *
                </span>
                <label className="pt-2 pl-1 font-weight-500">Feedback </label>
              </div>
              <div className="fixd_flow my-1 border-radius-10 border px-4 py-2">
                <SlateEditor
                  beforeMatch={beforeMatch}
                  afterStr={afterStr}
                  beforeStr={beforeStr}
                  onSelectUser={this.onSelectUser}
                  value={this.state.content}
                  setData={this.handleChange}
                  user_list={this.state.user_list}
                />
              </div>
            </div>
            <div
              className="sub-button my-3 "
              style={{ marginTop: "30px", float: "right" }}
            >
              <Button
                className="ant-btn-outline float-none"
                onClick={this.props.onCancelHandle}
              >
                Cancel
              </Button>
              <Button className="float-none" onClick={this.handelSubmit}>
                Submit
              </Button>
            </div>
            <br clear="all" />
          </div>
        </Spin>
      </div>
    );
  }
}
