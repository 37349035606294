import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createRootReducer from '../reducers'

const createBrowserHistory = require('history').createBrowserHistory;

const persistConfig = {
  key: 'marketingDetails',
  storage: storage,
  whitelist: ['marketingDetails']
};
export const history = createBrowserHistory();
const pReducer = persistReducer(persistConfig, createRootReducer(history));
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk,sagaMiddleware, routeMiddleware];


export default function configureStore(preloadedState) {
  const store = createStore(
    pReducer, // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return {store,persistor};
}
