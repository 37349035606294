import axios from "axios";
import { messageError } from "./AlertMessage";

export const BASE_URL = "https://api.log1.com"; //prod
// export const BASE_URL = "https://staging.log1.com"; //staging
// export const BASE_URL = "https://dev.log1.com/"; //dev
// export const BASE_URL = "http://localhost:8000/"; //local

// export const DASHBOARD_URL = "http://localhost:8000/add"//local
export const DASHBOARD_URL = "https://bta-prod.log1.com/add"//prod
// export const DASHBOARD_URL = "https://bta.log1.com/add"//staging
// export const DASHBOARD_URL =
//   " https://0i9fwc2dy5.execute-api.ap-south-1.amazonaws.com/api/bugs";

export const CLOUD_FRONT_URL = "https://d1a9x753n7feha.cloudfront.net/"//prod
// export const CLOUD_FRONT_URL = "https://d2us7jrqrv1djj.cloudfront.net/"//staging
export const ORIGIN_URL = "https://app.log1.com/" //prod
// export const ORIGIN_URL = "http://localhost:3001/"//local

export const REDIRECT_URL = "https://log1.com/"//prod
// export const REDIRECT_URL = "https://stagingapp.log1.com/"//staging
// export const REDIRECT_URL = "http://localhost:3000/"//local


export const LAMBDA_URL = "https://thex6uxtda.execute-api.ap-south-1.amazonaws.com/"//prod
// export const LAMBDA_URL = "https://p3hq1q02z6.execute-api.ap-south-1.amazonaws.com/"//staging


export const WEB_SOCKET_BASE_URL = " wss://34e098yk16.execute-api.ap-south-1.amazonaws.com/prod"



export let axiosJSON;

axiosJSON = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosJSON.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      delete axiosJSON.defaults.headers["authorization"];
      // delete axiosJSON.defaults.headers.common["X-Id-Token"];
      localStorage.removeItem("token");
      localStorage.removeItem("current_token");
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      window.location.reload();
    } else if (error.response.status >= 400 && error.response.status < 500) {
      if (error.response.data.hasOwnProperty("message")) {
        return Promise.reject(error.response.data.message);
      } else {
        return Promise.reject(error.response.data.error);
      }
    } else if (error.response.status === 500) {
      messageError("Server Error");
    }
  }
);

export default axiosJSON;
