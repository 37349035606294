import React from "react";
import { Col, Row } from "antd";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
const Layout = ({ match }) => {
  return (
    <div className="authLayout">
      <Row>
        <Col
          xs={12}
          md={12}
          lg={8}
          xl={8}
        >
          <div className="logoContainer">
            <img
              src={require("assets/images/logo-white.png")}
              alt="log1"
              title="log1"
            />
          </div>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={16}
          xl={16}
        >
          <div className="routesContainer">
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                component={asyncComponent(() => import("./SignIn"))}
              />
              <Route
                path={`${match.path}/forgot-password`}
                component={asyncComponent(() => import("./ForgotPassword"))}
              />
              <Route
                path={`${match.path}/verify-otp`}
                component={asyncComponent(() => import("./VarificationOtp"))}
              />
              <Route
                path={`${match.path}/reset-password`}
                component={asyncComponent(() => import("./ResetPassword"))}
              />
               <Route
                path={`${match.path}/reset-password-successful`}
                component={asyncComponent(() => import("./ResetPasswordSuccessful"))}
              />
            </Switch>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Layout;
