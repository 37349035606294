import React, { useState, useEffect } from "react";
import { Radio } from "antd";

const YesQuestion = (props) => {
  const [flag, setFlag] = useState(props.fieldObj.category === "child");
  const [val, setVal] = useState(props.fieldObj.values);

  useEffect(() => {
    if (props.answer && props.answer.length > 0) {
      let ans = props.answer.filter(
        (answer) => answer.question_answer.ques_id === props.fieldObj.id
      )[0];
      if (ans && ans.question_answer) {
        setFlag(true);
        setVal(ans.question_answer.answer);
      } else {
        setFlag(false);
        setVal(props.fieldObj.values);
      }
    }
    // eslint-disable-next-line
  }, []);

  const setQuestion = () => {
    let Question = [...props.testFields];
    if (props.fieldObj && props.fieldObj.dependent) {
      props.fieldObj.dependent.map((question) => {
        let isThere = Question.findIndex((ele) => ele.id === question.id);
        if (isThere === -1) {
          let obj = { ...question };
          obj["noRender"] = true;
          if (props.parentId) {
            obj["pId"] = props.parentId;
          }
          props.setTestFields(obj);
        }
        return null;
      });
    }
    // console.table(Question)
  };

  if (flag) {
    return (
      <div className="radio-margin">
        {setQuestion()}
        <label className="largeFont">
          {props.fieldObj.is_required && (
            <span style={{ color: "red", fontSize: 15 }}>*</span>
          )}
          {props.fieldObj.title} :
        </label>
        <div className="radio-margin">
          <Radio.Group
            value={val}
            onChange={(e) => {
              setVal(e.target.value);
              if (e.target.value === "No") {
                props.fieldObj.dependent.map((item) => {
                  props.handleFieldChange(
                    "",
                    item,
                    null,
                    null,
                    props.parentId,
                    true
                  );
                  return null;
                });
              }
              props.handleFieldChange(
                e.target.value,
                props.fieldObj,
                null,
                null,
                props.parentId,
                props.apiQuestion
              );
            }}
          >
            <Radio value={"Yes"}>Yes</Radio>
            <Radio value={"No"}>No</Radio>
          </Radio.Group>
        </div>
        {val === "Yes" && (
          <>
            {props.fieldObj &&
              props.fieldObj.dependent &&
              props.fieldObj.dependent.map((question) =>
                props.renderFormComponent(
                  question,
                  props.parentId,
                  props.apiQuestion,
                  props.answer
                )
              )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="radio-margin">
        {setQuestion()}
        <label className="largeFont">
          {props.fieldObj.is_required && (
            <span style={{ color: "red", fontSize: 15 }}>*</span>
          )}
          {props.fieldObj.title} :
        </label>
        <div className="radio radio-margin">
          <Radio.Group
            value={props.fieldObj.values}
            onChange={(e) => {
              // setVal(e.target.value)
              if (e.target.value === "No") {
                if (
                  props.fieldObj.title === "Did the test had coding question"
                ) {
                  props.resetOnNo();
                  props.setEnabled(false);
                }
                props.fieldObj.dependent.map((item) => {
                  props.handleFieldChange(
                    "",
                    item,
                    null,
                    null,
                    props.fieldObj.id,
                    false
                  );
                  return null;
                });
              }
              props.handleFieldChange(
                e.target.value,
                props.fieldObj,
                null,
                null,
                props.parentId,
                props.apiQuestion
              );
            }}
          >
            <Radio value={"Yes"}>Yes</Radio>
            <Radio value={"No"}>No</Radio>
          </Radio.Group>
        </div>
        {props.fieldObj.values === "Yes" && (
          <>
            {props.fieldObj &&
              props.fieldObj.dependent &&
              props.fieldObj.dependent.map((question) =>
                props.renderFormComponent(
                  question,
                  props.fieldObj.id,
                  props.apiQuestion,
                  props.answer
                )
              )}
          </>
        )}
      </div>
    );
  }
};

export default YesQuestion;
