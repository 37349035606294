import { Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

export const YesRemarkComponent = (props) => {
  return (
    <div className="radio-margin">
      <label className="largeFont">
        {props.fieldObj.is_required && (
          <span style={{ color: "red", fontSize: 15 }}>*</span>
        )}
        {props.fieldObj.title} :
      </label>
      <div className="radio">
        <Radio.Group
          value={props.fieldObj.values}
          onChange={(e) =>
            props.handleFieldChange(
              e.target.value,
              props.fieldObj,
              null,
              null,
              props.parentId,
              props.apiQuestion
            )
          }
        >
          <Radio value={"Yes"}>Yes</Radio>
          <Radio value={"No"}>No</Radio>
        </Radio.Group>
      </div>
      <>
        {props.fieldObj.values !== undefined &&
          props.fieldObj.values !== null &&
          props.fieldObj.values === "Yes" && (
            <div className="radio-margin mt-3">
              <label className="largeFont">Comment :</label>
              <TextArea
                placeholder="Added Comment Here"
                value={props.fieldObj.comment}
                onChange={(e) =>
                  props.handleFieldChange(
                    e.target.value,
                    props.fieldObj,
                    null,
                    true,
                    props.parentId,
                    props.apiQuestion
                  )
                }
              />
            </div>
          )}
      </>
    </div>
  );
};

export default YesRemarkComponent;
