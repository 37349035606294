import axios from "axios";
import { LAMBDA_URL } from "./Api";

export const dumbData = async (activityList) => {
  try {
    let user = localStorage.getItem("user");
    const userId = localStorage.getItem("current_employee_id");
    if(user){
      user = JSON.parse(user);
      const body = {
        EmpID: user.employee_id,
        EmpName: user.employee_name,
        Latitude: localStorage.getItem(`${userId}_latitude`),
        Longitude: localStorage.getItem(`${userId}_longitude`),
        Activities: activityList,
      };
      await axios.post(`${LAMBDA_URL}api/add_journal`, body);
      localStorage.removeItem("activityList");
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const putData = async (type, page) => {
  let activityList = localStorage.getItem("activityList");
  if (activityList) {
    activityList = JSON.parse(activityList);
    const newActivity = {
      id: activityList[activityList.length - 1].id + 1,
      activityName: type,
      page: page,
    };
    activityList = [...activityList, newActivity];
    localStorage.setItem("activityList", JSON.stringify(activityList));

    const totalSize = 4 * 1024 * 1024;
    const { size } = new Blob(Object.values(localStorage));
    if (size >= totalSize * 0.6 || activityList.length >= 100) {
        dumbData(JSON.parse(activityList));
    }
  } else {
    const newActivity = {
      id: 1,
      Desc: type,
      TabName: page,
    };
    localStorage.setItem("activityList", JSON.stringify([newActivity]));
  }
};
