import { Button, Tooltip, Upload } from "antd";
import React from "react";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";

const AttachmentComponent = (props) => {
  return (
    <div>
      <div>
        <div className={""}>
          <label className="largeFont">
            {props.fieldObj.is_required && (
              <span style={{ color: "red", fontSize: 15 }}>*</span>
            )}
            {props.fieldObj.title} :
          </label>
          <div>
            <Upload
              accept="application/pdf,.docx,image/jpeg,image/png,.zip,.rar,.7zip"
              onChange={(e) => props.handleFieldChange(e.file, props.fieldObj)}
              multiple={true}
              showUploadList={false}
              customRequest={(f) => props.customRequest(f)}
            >
              <Tooltip title="Upload Document">
                <div>
                  <Button
                    className="uploadbtn"
                    style={{ background: "transparent", color: "#6B6E70" }}
                    icon={<UploadOutlined />}
                  >
                    Upload Document
                  </Button>
                </div>
              </Tooltip>
            </Upload>
          </div>
        </div>
      </div>

      {props.fieldObj.values && props.fieldObj.values.length > 0 && (
        <div
          className="col-12"
          style={{ margin: "0px 0px 10px 0px", padding: "0px" }}
        >
          <label style={{ fontWeight: "bold" }}>Selected File</label>
          <div className="text-file-attached ">
            {props.fieldObj.values.map((obj) => (
              <div kay={obj.name} className="mb-2 ml-0 attachmentSty">
                {obj.name}
                <CloseCircleOutlined
                  className="deleteicon"
                  style={{
                    color: "red",
                    fontSize: 14,
                    marginLeft: "10px",
                    borderWidth: "0px",
                    borderColor: "white",
                    float: "revert",
                    marginTop: "0px",
                  }}
                  onClick={(ele) => {
                    props.handleFieldChange(null, props.fieldObj, obj.name);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttachmentComponent;
