export const TextFormatter = (inputText) => {
  let text = "";
  inputText.split("\n").map((line, index) => {
    if (line.length > 0) {
      text += line.replace(/\s{2,}/g, " ").trim()+"\n";
    } else {
      text += "\n";
    }
    return null;
  });
  text=text.slice(0, -1);
  text = text.replace(/\n{3,}/g, "\n\n");
  return text;
};
