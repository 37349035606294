import React, {useState, useEffect} from "react";
import {Layout, Popover, Button} from "antd";
import {toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import AppNotification from "../../components/AppNotification";
import {
  stopImpersonate, unreadNotify
} from "../../appRedux/actions";
import {messageError, messageSuccess} from "../../util/AlertMessage";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import UserProfile from "../Sidebar/UserProfile";
import Impersonation from "../../modals/impersonation";
import HandoverUser from "../../modals/handoverUser";
import axiosJSON,{ DASHBOARD_URL } from "../../util/Api";
import BugReporter from 'bug-reporter-react';
import { putData } from "../../util/trackingAPI";


const {Header} = Layout;

const Topbar = (props) => {
  const {width, navCollapsed, navStyle} = useSelector(({settings}) => settings);
  const {unread_notify} = useSelector(({common}) => common);
  const authUser = useSelector(({auth}) => auth.authUser);
  const newUser = useSelector(({auth}) => auth.newUser);
  const [notificationList, setNotificationList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [impersonateButton, openImpersonate] = useState(false);
  const [handoverButton, openHandover] = useState(false);
  const impersonateFlag = useSelector(({auth}) => auth.impersonateFlag);
  const dispatch = useDispatch();

  // const updateSearchChatUser = (evt) => {
  //   evt.preventDefault()
  //   dispatch(onSearch(evt.target.value))
  //   // setSearchText(evt.target.value);
  // };
  const hide = () => {
    putData(`notifications hidden`,'log1');
    setVisible(false);
  };

  const handleVisibleChange = visible => {
    if (visible) {
      let currentToken = localStorage.getItem("current_token");
      let token = localStorage.getItem("token");
      if (currentToken && impersonateFlag) {
        fetchData(currentToken)
      } else {
        fetchData(token)
      }
    }
    setVisible(visible);
  };

  const getStatus = (data,flag) =>{
    putData(`bug reporter button clicked`,'log1');
    if(flag){
      messageSuccess("Bug Reporter generated successfully" , [0.8])
    }else{
      messageError(data,[0.8])
    }
  }

  const stopImpersonation = () => {
    putData(`Impersonation stopped`,'log1');
    dispatch(stopImpersonate());
  }

  const fetchData = (token) => {
    setNotificationList([])
    axiosJSON.defaults.headers['authorization'] = "Token " + token;
    axiosJSON.get('api/emp_notify/?page=1')
      .then(res => {
        const notf = res.data.data
        setNotificationList(notf)
      }).catch(function (error) {
       messageError(error.message);
    })
  }
  useEffect(() => {
    let currentToken = localStorage.getItem("current_token");
    let token = localStorage.getItem("token");
    if (currentToken && impersonateFlag) {
      fetchData(currentToken)
      dispatch(unreadNotify(currentToken));
    } else {
      fetchData(token)
      dispatch(unreadNotify(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateFlag])
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <img className="logo-img" style={{width: '6%'}} alt="" src={require("assets/images/logo-white.png")}/>

      {
        newUser &&
        <div className="ant-row" style={{marginLeft: '40px'}}>
          <h3 className="gx-text-white">Impersonated user : {newUser.employee_name}</h3>
        </div>
      }
      {/*<SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"*/}
      {/*           placeholder="Search in app..."*/}
      {/*           onChange={updateSearchChatUser}*/}
      {/*           value={searchText}/>*/}
      <ul className="gx-header-notifications gx-ml-auto log-header">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          {/*<Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={*/}
          {/*  <SearchBox styleName="gx-popover-search-bar"*/}
          {/*             placeholder="Search in app..."*/}
          {/*             onChange={updateSearchChatUser}*/}
          {/*             value={searchText}/>*/}
          {/*} trigger="click">*/}
          {/*  <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>*/}
          {/*</Popover>*/}
        </li>

        {/*{*/}
        {/*  authUser && authUser.roles.includes("superadmin") &&*/}
        {/*  <li>*/}
        {/*    <Button onClick={() => {*/}
        {/*      openHandover(true)*/}
        {/*    }}>Handover</Button>*/}
        {/*  </li>*/}
        {/*}*/}

        <li>
          {authUser && (authUser.roles.includes("superadmin")) ?
          !impersonateFlag ?
            <Button onClick={() => {
              openImpersonate(true)
            }} className="gx-mb-0">Impersonate</Button>
            : <Button onClick={() => {
              stopImpersonation()
            }}  className="gx-mb-0"> Stop Impersonate</Button>
            : null
          }
        </li>
        <li className="gx-msg">
          <Popover overlayClassName="gx-popover-horizantal" placement="topRight"
                   content={
                     <AppNotification
                       notificationList={notificationList}
                       fetchData={fetchData} visible={visible} hide={hide} history={props.history}/>}
                   visible={visible}
                   onVisibleChange={handleVisibleChange}
                   trigger="click">
               <span className="gx-pointer gx-status-pos gx-d-block">
            <i className="icon icon-notification"/>
                 {unread_notify > 0 &&
                 <span
                   className={`gx-status gx-status-rtl gx-font-small ${unread_notify.length > 100 ? `gx-xlarge` : unread_notify.length > 10 ? `gx-large` : 'gx-medium'}  gx-orange`}>{unread_notify}</span>}
               </span>
          </Popover></li>
        {/*<li className="gx-msg">*/}
        {/*  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
        {/*           content={<AppNotification history={props.history}/>}*/}
        {/*           trigger="click">*/}
        {/*       <span className="gx-pointer gx-status-pos gx-d-block">*/}
        {/*    <MdNotificationsActive style={{fontSize:'20px'}}/>*/}
        {/*         <span className="gx-status gx-status-rtl gx-font-small gx-large gx-orange-new">3</span>*/}
        {/*       </span>*/}
        {/*  </Popover></li>*/}
        <li>
          <UserProfile/>
        </li>
      </ul>
      {
        impersonateButton &&
        <Impersonation impersonateButton={impersonateButton} openImpersonate={openImpersonate} authUser={authUser}/>
      }
      {
        handoverButton &&
        <HandoverUser handoverButton={handoverButton} openHandover={openHandover}/>
      }
      <BugReporter
        buttonStyle={{ position: "fixed", bottom: 500, right: 200 }}
        url={DASHBOARD_URL}
        name={props.authInfo?props.authInfo.employee_name:"None"}
        email={props.authInfo?props.authInfo.email:"None"}
        appName={"Log1"}
        getStatus={getStatus}
      />

    </Header>
  );
};

export default React.memo(Topbar);
