import React, { useState } from "react";
import { Button, Modal, Tag } from "antd";
import { FiBell, FiBriefcase, FiCalendar } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { BsBuilding } from "react-icons/bs";
import CallSupervisorFeedback from "./Marketing/Interview/callSupervisorFeedback";
import { messageError, messageSuccess } from "../util/AlertMessage";
import axiosJSON from "../util/Api";

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );
const PendingFeedbackCard = ({ feedback, getPendingFeedbackData }) => {
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const handleFeedbackFormSubmit = () => {
    getPendingFeedbackData();
    setFeedbackFormOpen(false);
  };
  return (
    <div className="pending-feedback-card">
      <div className="pending-feedback-card-upper">
        <div>
          <BiUser className="icons-consultant" />
          <span className="font-heavy-Inter mr-1">
            {feedback.consultant.name}
          </span>
          <Tag className="round-tag">Round {feedback.round}</Tag>
        </div>
        <div
          onClick={() => {
            setFeedbackFormOpen(true);
          }}
          style={{
            cursor: "pointer",
            color: "var(--primary-400-base, #26305C)",
          }}
        >
          + Add Feedback
        </div>
      </div>
      <div className="pending-feedback-card-lower">
        <p>
          <FiCalendar className="icon-16" />
          <span>Scheduled at</span>
        </p>
        <p style={{ color: "var(--neutral-600, #717885)" }}>
          {feedback?.schedule}
        </p>
        <p>
          <BsBuilding className="icon-16" />
          <span>Client</span>
        </p>
        <p style={{ color: "var(--neutral-600, #717885)" }}>
          {feedback?.submission?.client}
        </p>
        <p>
          <FiBriefcase className="icon-16" />
          <span>Job Position</span>
        </p>
        <p style={{ color: "var(--neutral-600, #717885)" }}>
          {titleCase(feedback?.submission?.job_title)}
        </p>
      </div>
      {feedbackFormOpen ? (
        <Modal
          title="Add Supervisor's Feedback"
          visible={feedbackFormOpen}
          footer={null}
          maskClosable={false}
          closable={false}
        >
          <CallSupervisorFeedback
            handleCancel={() => {
              setFeedbackFormOpen(false);
            }}
            handleClose={handleFeedbackFormSubmit}
            interview={feedback}
          />
        </Modal>
      ) : null}
    </div>
  );
};
const NotifySupervisor = ({
  feedbacks,
  userId,
  count,
  getPendingFeedbackData,
}) => {
  const handleRemindMeLater = () => {
    const body = {
      user_id: userId,
      types: ["interview"],
    };
    axiosJSON
      .post("/api/emp_notify/remind_me_later/", body)
      .then(({ status, data }) => {
        messageSuccess(data.message);
        getPendingFeedbackData();
      })
      .catch((err) => messageError(err));
  };
  return (
    <div>
      <p className="font-light">
        Please Add feeedback for all of your pending Interview feedbacks to
        proceed
      </p>
      <div className="remind-later-div">
        <div>
          <p className="mb-1" style={{ color: "#E8930B" }}>
            Remind me later
            <Tag style={{ marginLeft: "5px",marginBottom: "0px" }} color="volcano">
              {4 - count} Attempts remaining
            </Tag>
          </p>

          <span
            className="font-light"
            style={{ fontSize: "12px", color: "#1B1C20" }}
          >
            We’ll remind you to add feedback every two hours. You can delay
            feedback three times, but after that, you will be obligated to add
            feedback to continue
          </span>
        </div>
        <Button onClick={handleRemindMeLater} disabled={count > 3}>
          <FiBell />
          <p>Remind me later</p>
        </Button>
      </div>
      <div className="feedback-due-list">
        {feedbacks.map((feedback) => {
          return (
            <PendingFeedbackCard
              feedback={feedback}
              getPendingFeedbackData={getPendingFeedbackData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NotifySupervisor;
