import React from "react";
import NotificationItem from "./NotificationItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import axiosJSON from "util/Api";
import {Link} from "react-router-dom";
import {Skeleton} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {unreadNotify} from "../../appRedux/actions";
import {messageError} from "util/AlertMessage";
import { putData } from "../../util/trackingAPI";

const comparator = (prevProps, nextProps) => {
  if (prevProps.visible !== nextProps.visible) {
    return true;
  }
  return false;
}
const AppNotification = React.memo((props) => {
  const dispatch = useDispatch();

  const markAllRead = () => {
    putData(`mark all read in notifications clicked`,'log1');
    axiosJSON.get('api/emp_notify/mark_all_read/')
      .then(res => {
        let token = localStorage.getItem("token");
        let currentToken = localStorage.getItem("current_token")
        if (currentToken) {
          dispatch(unreadNotify(currentToken))
          props.fetchData(currentToken)
        } else {
          dispatch(unreadNotify(token))
          props.fetchData(token)
        }

      }).catch(function (error) {
       messageError(error.message);
    })

  }
  return (
      <Skeleton loading={props.notificationList.length <= 0} active>
      <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0">Notifications</h3>
          <CloseOutlined onClick={props.hide}/>
        </div>
        <CustomScrollbars className="gx-popover-scroll">
          <ul className="gx-sub-popover">
            {props.notificationList.map((notification, index) => <NotificationItem key={index} id={notification.id}
                                                                                   notification={notification}
                                                                                   is_read={notification.unread}
                                                                                   hide={props.hide}
                                                                                   get={props.fetchData}/>)
            }
          </ul>
        </CustomScrollbars>
        <Link to='/notification'><span onClick={props.hide}>Show All</span></Link>
        <Link to='#'><span style={{float: 'right'}} onClick={markAllRead}>Mark All Read</span></Link>
      </Auxiliary>
      </Skeleton>
  )
},comparator);

export default AppNotification;

