import { Button, Input, Form } from "antd";
import React, { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

const ChangePassword = (props) => {
  const [passwordValidation, setPasswordValidation] = useState({
    minimumCharacter: false,
    upperLetter: false,
    number: false,
    specialCharacter: false,
  });

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validation = (event) => {
    const pattern = /[!@#$%^&()*_\-=[\]{};':"\\|,.<>?]+/;
    const passwordValue = event.target.value;
    setPasswordValidation({
      minimumCharacter: passwordValue.length >= 8 ? true : false,
      upperLetter: passwordValue.search(/[A-Z]/) > -1 ? true : false,
      number: passwordValue.search(/[0-9]/) > -1 ? true : false,
      specialCharacter: passwordValue.search(pattern) > -1 ? true : false,
    });
  };

  const validateNewPassword = (event, value) => {
    if (value) {
      for (let i in passwordValidation) {
        if (!passwordValidation[i]) {
          return Promise.reject(
            "Password does not match the following validation."
          );
        }
      }
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = async (event, value) => {
    try {
      await validateNewPassword(event, form.getFieldValue("password"));
      if (value && value !== form.getFieldValue("password")) {
        return Promise.reject(
          "New Password and Confirm Password does not match."
        );
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 col-sm-6 col-xs-12">
        <div className="profilesection pb-3">
          <Form
            initialValues={{ remember: true }}
            name="basic"
            layout="vertical"
            form={form}
            onFinish={() =>
              props.changePassword(
                form.getFieldValue("current_password"),
                form.getFieldValue("password")
              )
            }
            onFinishFailed={onFinishFailed}
          >
            <FormItem
              style={{ marginBottom: "15px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your current password.",
                },
                {
                  validateTrigger: "onSubmit",
                },
              ]}
              label="Current Password"
              name="current_password"
            >
              <div className="pt-3">
                <div className="subhead1">
                  <Input.Password type="password" />
                </div>
              </div>
            </FormItem>
            <FormItem
              style={{ marginBottom: "15px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your new password.",
                },
                {
                  validator: validateNewPassword,
                  validateTrigger: "onSubmit",
                },
              ]}
              label="New Password"
              name="password"
            >
              <div className="pt-3">
                <div className="subhead1">
                  <Input.Password
                    type="password"
                    onChange={(e) => validation(e)}
                  />
                </div>
              </div>
            </FormItem>
            <div style={{ marginTop: "6px", marginBottom: "24px" }}>
              <p
                style={{
                  marginBottom: "4px",
                  color: passwordValidation.minimumCharacter
                    ? "#28A745"
                    : "#9AA1B0",
                }}
              >
                <CheckOutlined />
                <span style={{ marginLeft: "8px" }}>Minimum 8 characters</span>
              </p>
              <p
                style={{
                  marginBottom: "4px",
                  color: passwordValidation.upperLetter ? "#28A745" : "#9AA1B0",
                }}
              >
                <CheckOutlined />
                <span style={{ marginLeft: "8px" }}>One uppercase letter</span>
              </p>
              <p
                style={{
                  marginBottom: "4px",
                  color: passwordValidation.number ? "#28A745" : "#9AA1B0",
                }}
              >
                <CheckOutlined />
                <span style={{ marginLeft: "8px" }}>One number</span>
              </p>
              <p
                style={{
                  marginBottom: "0px",
                  color: passwordValidation.specialCharacter
                    ? "#28A745"
                    : "#9AA1B0",
                }}
              >
                <CheckOutlined />
                <span style={{ marginLeft: "8px" }}>
                  One special character(!@#$%^&*_)
                </span>
              </p>
            </div>
            <FormItem
              style={{ marginBottom: "15 px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your confirm password.",
                },
                {
                  validator: validateConfirmPassword,
                  validateTrigger: "onSubmit",
                },
              ]}
              label="Confirm Password"
              name="c_password"
            >
              <div className="pt-3">
                <div className="subhead1">
                  <Input.Password type="password" />
                </div>
              </div>
            </FormItem>
            <FormItem>
              <div className="pt-3">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button onClick={props.onCancel}>Cancel</Button>
              </div>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
